import { GET_BALANCE_NEW } from "../actions/newUser";


export const INITIAL_STATE = {
 
  newBalance:[]

};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BALANCE_NEW:
      
      return {
        ...state,
        newBalance:action.payload
      }

      default: return state
  

  }

}
