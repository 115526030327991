// Включаем двухфакторку
import {
    accountError,
    accountPending,
    accountSuccess,
    accountUpdatePending,
    FADisabled,
    FAEnabled,
    FAError, logout, stopPollingProfile, updateToken
} from "../actions/user";
import {API_ENDPOINT} from "../settings";

export default function disableFAAction(fa) {
    return dispatch => {
        const token = localStorage.token;

        if (!token) {
            return;
        }

        dispatch(accountUpdatePending());

        fetch(API_ENDPOINT + '/2fa/disable', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({'fa': fa})
        })
            .then(res => {
                if (res.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }

                return res.json();
            })
            .then(res => {
                if (res.ok === false) {
                    return dispatch(FAError(res.message));
                }

                if (res.hasOwnProperty('updated_token') && res.updated_token) {
                    dispatch(updateToken(res.updated_token));
                }
                
                return dispatch(FADisabled());
            })
            .catch(error => {
                if (error.status >= 500 && !error.message) {
                    error.message = "error.server_wrong";
                  }
                return dispatch(FAError(error));
            });
    }
}