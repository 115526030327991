import { API_ENDPOINT } from "../settings";
import {
  registerError,
  registerPending,
  registerSuccess,
} from "../actions/user";

export default function registerAction(email, password, telegram, recaptcha) {
  return (dispatch) => {
    // Мы в процессе загрузки данных
    dispatch(registerPending());

    fetch(API_ENDPOINT + "/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        telegram: telegram,
        recaptcha: recaptcha,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.ok === false) {
          return dispatch(registerError(res.message));
        }

        return dispatch(registerSuccess(res.data));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(registerError(error));
      });
  };
}
