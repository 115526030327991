import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';

const PIN_CODE_LENGTH = 4;

class PinCodeInput extends PureComponent {
    inputRef = React.createRef();

    componentDidUpdate(prevProps) {
        const { isValid, isClear } = this.props;

        if (
            (isValid !== prevProps.isValid && !isValid)
            || (isClear !== prevProps.isClear && isClear)
        ) {
            this.clear();
        }
    }

    clear = () => {
        if (this.inputRef.current) {
            if (this.inputRef.current.textInput[0]) this.inputRef.current.textInput[0].focus();
            this.inputRef.current.state.input[0] = "";
            this.inputRef.current.state.input[1] = "";
            this.inputRef.current.state.input[2] = "";
            this.inputRef.current.state.input[3] = "";
            this.forceUpdate();
        }
    };

    render() {
        const { isValid } = this.props;
        return (
            <ReactCodeInput
                {...this.props}
                isValid={isValid}
                type="password"
                ref={this.inputRef}
                fields={PIN_CODE_LENGTH}
            />
        )
    }
}

PinCodeInput.propTypes = {
    isValid: PropTypes.bool,
    isClear: PropTypes.bool,
};

PinCodeInput.defaultProps = {
    isValid: true,
    isClear: false,
};

export default PinCodeInput;