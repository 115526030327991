import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withLocalize, Translate} from 'react-localize-redux';
import AuthLayout from '../Layout/AuthLayout';
import {ActionButton} from '../Buttons';
import BackBtn from '../Buttons/BackBtn';

class OpenWallet extends PureComponent {
    state = {
        phrase: ''
    };

    onChange = (element) => {
        this.setState({phrase: element.target.value});

        this.props.onChange(element.target.value);
    };

    onSubmit = e => {
        const {onSubmit} = this.props;
        const {phrase} = this.state;

        if (phrase) {
            onSubmit();
        }
    };

    render() {
        const {onBackClick} = this.props;
        const {phrase} = this.state;

        return (
            <>
                <div className="formbox mt-2">
                    <form action="/" id="form-registration" onSubmit={this.onSubmit}>
                        <Translate>
                            {({ translate }) =>
                                <input
                                    type="text"
                                    onChange={this.onChange}
                                    value={phrase}
                                    placeholder={translate("mnemo.enter_mnemo")}
                                />
                            }
                        </Translate>

                        <div className="clear"></div>

                        <div className="form-group mt-4">
                            <div className="btn-group-vertical w-100 mnemo-btns">
                                <ActionButton className="rounded btn-bg" disabled={!phrase} type="submit">
                                    <Translate id="auth.login"/>
                                </ActionButton>

                                <BackBtn className="rounded mt-2 btn-bg" type="button" onClick={onBackClick}/>
                            </div>
                        </div>

                        <div className="clear"></div>
                    </form>
                </div>
            </>
        );
    }
}

OpenWallet.propTypes = {
    onBackClick: PropTypes.func,
    onSubmit: PropTypes.func,
    phrase: PropTypes.string.isRequired
};

OpenWallet.defaultProps = {
    onBackClick: () => {},
    onSubmit: () => {}
};

const enhance = compose(
    withLocalize
);


export default enhance(OpenWallet);
