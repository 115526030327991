import React from 'react';

const AuthLogo = () => {
    return (
        <a href="#" className="logo logo-admin" style={{borderBottom: 'none'}}>
            <img style={{marginLeft: -4, marginTop: -5}} src="/assets/images/logo/3.png" height="80" alt="logo"/>
        </a>
    )
};

export default AuthLogo;