export const SET_ACTIVE_WALLET = "SET_ACTIVE_WALLET";
export const CREATE_NEW_WALLET = "CREATE_NEW_WALLET";
export const EDIT_WALLET = "EDIT_WALLET";
export const REMOVE_WALLET = "REMOVE_WALLET";

export function setActiveWallet(data) {
    return {
        type: SET_ACTIVE_WALLET,
        data: data
    }
}

export function createNewWallet(data) {
    return {
        type: CREATE_NEW_WALLET,
        data: data
    }
}

export function editWallet(data) {
    return {
        type: EDIT_WALLET,
        data: data
    }
}

export function removeWallet(data) {
    return {
        type: REMOVE_WALLET,
        data: data
    }
}