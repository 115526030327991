import {
    txSendReinvestPending,
    txSendReinvestSuccess,
    txSendReinvestError,
} from "../actions/reinvest";
import {generateAccountFromMnemonic, generatePushableTransaction} from "../utils/crypto";
import * as blockchain from "../utils/blockchain";

export default function sendReinvest(user) {
    return (dispatch, getState) => {
        dispatch(txSendReinvestPending());

        const accountData = generateAccountFromMnemonic(user.mnemonic);

        blockchain.getAccountInfo(accountData.address).then(accountInfo => {
            const accountNumber = accountInfo.value.account_number;
            const sequence = accountInfo.value.sequence;

            const pushableTx = generatePushableTransaction(
                accountNumber.toString(),
                sequence.toString(),
                accountData.pubKey,
                accountData.privKey,
                null,
                null,
                true
            );


            // Send that tx to our blockchain
            blockchain.broadcastTx(pushableTx).then(response => {
                dispatch(txSendReinvestSuccess());
            });
        });
    }
}
