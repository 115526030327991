import React, {Component} from "react"
import {Modal, Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { withLocalize, Translate } from "react-localize-redux";
import {connect} from "react-redux";

import editWallet from "../../api/editWallet";

class EditWallet extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
        }

        this.edit = this.edit.bind(this);
    }

    edit = () => {
        this.props.dispatch(editWallet(this.props.address, this.state.name));
        this.props.toggle()
    }

    render(){
        const {isOpen, toggle} = this.props
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <Translate id="wallets.edit_wallet" />
                </ModalHeader>
                <ModalBody>
                    <form className="form-horizontal m-t-30" action="/">
                        <div className="form-group">
                            <label htmlFor="walletname">
                                <Translate id="wallets.name"/>
                            </label>

                            <Translate>
                                {({ translate }) => (
                                    <input
                                        type="text"
                                        value={this.state.name}
                                        maxLength={50}
                                        onChange={e => this.setState({ name: e.target.value })}
                                        className="form-control"
                                        id="walletname"
                                        placeholder={translate("wallets.enter_name")}
                                    />
                                )}
                            </Translate>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.edit}>
                        <Translate id="wallets.edit"/>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default connect()(withLocalize(EditWallet));