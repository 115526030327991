import { resetPasswordTokenPadding, resetPasswordTokenError, resetPasswordTokenSuccess  } from '../actions/user';
import { API_ENDPOINT } from '../settings';


export default function fetchResetPasswordToken(token) {
    return dispatch => {
        if (!token) {
            return;
        }

        dispatch(resetPasswordTokenPadding());

        return fetch(API_ENDPOINT + '/reset-password/validate',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'token': token,
            })
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (!res.ok) {
                    dispatch(
                      resetPasswordTokenError(
                        `error.${res.message.toLowerCase().replace(' ', '_')}`
                      )
                    );
                } else {
                    dispatch(resetPasswordTokenSuccess());
                }
            })
            .catch(error => {
                if (error.status >= 500) {
                    dispatch(resetPasswordTokenError("error.server_wrong"));
                  }
                dispatch(resetPasswordTokenError(error.message))
            })
    }
}
