import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../store/action";
import AuthLogo from "../components/Logo/AuthLogo";
import { Translate, withLocalize } from "react-localize-redux";
import getResetPasswordLink from "../api/getResetPasswordLink";
import { THEME_LIGHT } from "../settings";
import ReactCountryFlag from "react-country-flag";
import ChooseLang from "../components/ChooseLang/ChooseLang";

class GetResetPasswordLinkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      wasSent: false,
    };
  }

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: true });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email } = this.state;

    if (email) {
      this.setState({ wasSent: true });

      this.props.dispatch(
        getResetPasswordLink(email, this.props.activeLanguage.code)
      );
    }
  };

  onChangeField = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  render() {
    let errorMessage = null;

    if (this.props.user.get_reset_password_link_error) {
      errorMessage = (
        <div className="text-danger">
          <Translate id={this.props.user.get_reset_password_link_error} />
        </div>
      );
    }

    let hasError = !!this.props.user.get_reset_password_link_error;

    const button = this.props.user.pending ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <div className="submit">
        <button type="submit">
          <Translate id="auth.get_reset_password_link" />
        </button>
      </div>
    );

    let content = (
      <div className="formbox">
        <form action="/" id="form-registration" onSubmit={this.onSubmit}>
          <Translate>
            {({ translate }) => (
              <input
                type="text"
                size="50"
                name="email"
                value={this.state.email}
                onChange={this.onChangeField("email")}
                className={`email ${hasError ? "error" : ""}`}
                id="email"
                placeholder={translate("auth.enter_email")}
              />
            )}
          </Translate>

          {hasError && errorMessage}

          <div className="clear"></div>

          {button}

          <div className="clear"></div>
        </form>
      </div>
    );

    if (!hasError && this.state.wasSent) {
      content = (
        <div
          className="text-center mb-3 mt-3 font-20"
          style={{ marginTop: 30 }}
        >
          <Translate id="auth.get_reset_link" />
        </div>
      );
    }

    return (
      <>
        <div id="page">
          <div id="box-4" style={{ zIndex: 7 }}>
            <div id="box-4-w">
              <ChooseLang
                languages={this.props.languages}
                setActiveLanguage={this.props.setActiveLanguage}
                activeLanguage={
                  this.props.activeLanguage && this.props.activeLanguage.code
                }
              />

              <Link to="/" className="logo">
                Ouroboros
              </Link>
            </div>
          </div>

          <div className="clear"></div>

          <div id="box-4">
            <div className="box-4-l">
              <h2>
                <Translate id="auth.reset_password" />
              </h2>
              <p>
                <Translate id="auth.forget_password" />
              </p>

              {content}

              <h6 className="mt-4">
                <Translate id="auth.remember_your_password" />{" "}
                <Link to="/login" className={"font-500 text-primary"}>
                  <Translate id="auth.login" />
                </Link>
              </h6>

              <div className="clear"></div>
            </div>

            <div className="box-4-r">
              <img
                src={
                  this.props.theme === THEME_LIGHT
                    ? require("../assets/img/14-white.png")
                    : require("../assets/img/14.png")
                }
                alt=""
              />

              <p>
                <Translate id="auth.get_access" />
              </p>

              <div className="clear"></div>
            </div>

            <div className="clear"></div>
          </div>
          <div
            style={{ marginTop: "126px" }}
            className="lang"
            onClick={() => {
              this.setState({ ...this.state, show: !this.state.show });

              document.getElementsByClassName("rodal")[0].style.display = "";
            }}
          >
            {this.props.languages.map((l) => {
              if (l.active) {
                return (
                  <div className="lang__block">
                    <ReactCountryFlag
                      countryCode={l.code === "en" ? "gb" : l.code}
                      svg
                    />
                    <div>{l.code.toUpperCase()}</div>
                    {!this.state.show ? (
                      <i class="fas fa-caret-down" />
                    ) : (
                      <i class="fas fa-caret-up" />
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginpage: state.ui_red.loginpage,
  user: state.User,
  theme: state.Local.theme,
});

export default connect(mapStateToProps)(withLocalize(GetResetPasswordLinkPage));
