// Конвертим монетки в одинаковый формат
export default function dailyPercent (daily_percent, structure_coff, savings_coff) {
    const daily = parseInt(daily_percent, 10) / 100;
    const structure = parseInt(structure_coff, 10) / 100;
    const savings = parseInt(savings_coff, 10) / 100;

    let result = daily;

    if (structure > 1) {
        result *= structure;
    }

    if (savings > 1) {
        result *= savings;
    }

    return Math.floor(result * 10000) / 10000;
}