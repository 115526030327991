import {
  accountUpdateSuccess,
  accountUpdatePending,
  accountUpdateError,
  logout,
  stopPollingProfile,
  updateToken,
} from "../actions/user";
import { API_ENDPOINT } from "../settings";

export default function updateAccountAction(data) {
  return (dispatch) => {
    // Мы в процессе загрузки данных
    const token = localStorage.token;

    if (!token) {
      return;
    }

    dispatch(accountUpdatePending());

    fetch(API_ENDPOINT + "/profile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }

        return res.json();
      })
      .then((res) => {
        if (!res.ok) {
          dispatch(accountUpdateError(res.message));
        } else {
          dispatch(accountUpdateSuccess(res.data));
        }

        if (res.hasOwnProperty("updated_token") && res.updated_token) {
          dispatch(updateToken(res.updated_token));
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }
        if (error.status >= 500) {
          dispatch(accountUpdateError("error.server_wrong"));
        }
        dispatch(accountUpdateError(error.message));
      });
  };
}
