import {ofType} from 'redux-observable';
import {flatMap, takeUntil, map, mergeMap} from 'rxjs/operators';
import {NODE_ENDPOINT, NODE_ENDPOINT_NEW} from "../settings";
import {timer} from 'rxjs'
import {
    FETCH_TXS,
    START_TXS_POLLING,
    TX_FETCH_SUCCESS,
    txFetchSuccess
} from "../actions/tx";

function compare(a, b) {
    const a_height = parseInt(a.height, 10);
    const b_height = parseInt(b.height, 10);

    if (a_height < b_height) {
        return 1;
    }
    if (a_height > b_height) {
        return -1;
    }

    // Парамайнинг чарджи должны быть сверху
    if (a_height === b_height && a.tx === undefined && a.tx !== undefined) {
        if (a.tx === undefined) {
            return 1;
        }

        if (b.tx === undefined) {
            return -1;
        }
    }

    return 0;
}

// epic
const fetchTxsEpic = (action$, state$) => action$.pipe(
    ofType(FETCH_TXS),

    map(action => action.payload),
    mergeMap(async (address) => {
            const outcoming = await fetch(`${NODE_ENDPOINT}/txs?limit=100&page=1&message.sender=${address}`).then(res => res.json());
            const incoming = await fetch(`${NODE_ENDPOINT}/txs?limit=100&page=1&transfer.recipient=${address}`).then(res => res.json());

            let concated = outcoming.txs.concat(incoming.txs);

            const result = [];
            const uniqueHashes = {};

            for (let i in concated) {
                if (uniqueHashes[concated[i].txhash] === undefined) {
                    uniqueHashes[concated[i].txhash] = true;

                    result.push(concated[i]);

                }
            }

            result.sort(compare);

            return txFetchSuccess(result);
        }
    )
);

const pollTxsEpic = (action$, state$) => action$.pipe(
  ofType(START_TXS_POLLING),

  map(action => action.payload),

  flatMap(address =>
      timer(0, 3000).pipe(
          takeUntil(action$.pipe(ofType(TX_FETCH_SUCCESS))),
          mergeMap(async () => {
            const outcoming = await fetch(`${NODE_ENDPOINT_NEW}/txs/${address}?type=received&page=1`).then(res => res.json());
            const incoming = await fetch(`${NODE_ENDPOINT_NEW}/txs/${address}?type=send&page=1`).then(res => res.json());

                  let concated = outcoming.results.concat(incoming.results);

                  const result = [];
                  const uniqueHashes = {};

                  for (let i in concated) {
                   
                      if (uniqueHashes[concated[i].hash] === undefined) {
                          uniqueHashes[concated[i].hash] = true;

                          result.push(concated[i]);

                      }
                  }

                  result.sort(compare);

                  if (state$.value.Tx.Txs.length !== result.length) {
                      return txFetchSuccess(result);
                  }
              }
          )
      ),
  ),
);

export default pollTxsEpic;