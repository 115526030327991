import {NODE_ENDPOINT} from "../settings";
import {
    rewardsFetchSuccess,
} from "../actions/validators";
import convertCoins from "../utils/coins";

// Вытаскиваем список наград делегатора
export default function fetchRewardsAction(address) {
    return (dispatch, getState) => {
        fetch(NODE_ENDPOINT + '/distribution/delegators/' + address + '/rewards')
            .then(res => {
                return res.json();
            })
            .then(data => {
                const result = {};

                let total = 0;

                data.result['rewards'].map((value, key) => {
                    if (value['reward'] === null) {
                        result[value['validator_address']] = 0;

                        return true;
                    }

                    const amount = parseInt(value['reward'][0]['amount'], 10);

                    result[value['validator_address']] = convertCoins(amount);

                    total += amount;

                    return true;
                });

                dispatch(rewardsFetchSuccess(result, convertCoins(total)));

                return result;
            })
            .catch(error => {
                console.log(error);
            })
    }
}