// Вытаскиваем данные аккаунта - email, адрес, ключ, баланс, т.д.
import {
  logout,
  profileError,
  profilePending,
  profileSuccess,
  stopPollingProfile
} from "../actions/user";
import { NODE_ENDPOINT } from "../settings";

export default function fetchProfileAction(address) {
  return (dispatch, getState) => {
    // Мы в процессе загрузки данных
    dispatch(profilePending());

    fetch(NODE_ENDPOINT + "/ouroboros/profile/" + address + "/ouro2")
      .then(res => {
        return res.json();
      })
      .then(res => {
        if (res.error) {
          return dispatch(profileError(res.error));
        }

        dispatch(profileSuccess(res.result));

        return res;
      })
      .catch(error => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(profileError(error));
      });
  };
}