import {NODE_ENDPOINT} from "../settings";
import {validatorsUpdatePending, validatorsUpdateSuccess} from "../actions/validators";
import convertCoins from "../utils/coins";

function compare(a, b) {
    if (parseInt(a.delegated, 10) < parseInt(b.delegated, 10)) {
        return 1;
    }

    if (parseInt(a.delegated, 10) > parseInt(b.delegated, 10)) {
        return -1;
    }

    if (a.jailed !== b.jailed) {
        return a.jailed ? 1 : -1;
    }

    return 0;
}

// Вытаскиваем список транзакций юзера
export default function fetchValidatorsAction(address) {
    return (dispatch, getState) => {
        // Мы в процессе загрузки данных
        dispatch(validatorsUpdatePending());

        fetch(NODE_ENDPOINT + '/staking/validators?limit=100')
            .then(res => {
                return res.json();
            })
            .then(validators => {
                fetch(NODE_ENDPOINT + '/staking/validators?status=unbonding&limit=100')
                    .then(res => {
                        return res.json();
                    })
                    .then(unbodingValidators => {
                        fetch(NODE_ENDPOINT + '/staking/validators?status=unbonded&limit=100')
                            .then(res => {
                                return res.json();
                            })
                            .then(unbondedValidators => {
                                validators = unbodingValidators['result'].concat(validators['result']).concat(unbondedValidators['result']);

                                fetch(NODE_ENDPOINT + '/staking/delegators/' + address + '/delegations').then(res => {
                                    return res.json();
                                }).then(delegations => {
                                    const delegationsResult = {};

                                    let total = 0;

                                    delegations['result'].map((value, key) => {
                                        delegationsResult[value['validator_address']] = value['balance'];

                                        total += convertCoins(value['balance']);

                                        return true;
                                    });

                                    validators.map((validator, key) => {
                                        if (delegationsResult[validator['operator_address']] !== undefined) {
                                            validators[key]['delegated'] = delegationsResult[validator['operator_address']];
                                        } else {
                                            validators[key]['delegated'] = "0";
                                        }

                                        return true;
                                    });

                                    validators.sort(compare);

                                    dispatch(validatorsUpdateSuccess(validators, total));

                                    return validators;
                                })
                            })
                    })
            })
            .catch(error => {
                console.log(error);
            })
    }
}