import {
  getResetPasswordLinkError,
  getResetPasswordLinkPadding,
  getResetPasswordLinkSuccess,
} from "../actions/user";
import { API_ENDPOINT } from "../settings";

export default function getResetPasswordLink(email, language) {
  return (dispatch) => {
    dispatch(getResetPasswordLinkPadding());
    console.log("");
    return fetch(API_ENDPOINT + "/reset-password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        language: language,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (!res.ok) {
          dispatch(getResetPasswordLinkError(res.message));
        }
        return dispatch(getResetPasswordLinkSuccess(res.data));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(getResetPasswordLinkError(error));
      });
  };
}
