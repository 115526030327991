import { ofType } from "redux-observable";
import { flatMap, mergeMap, map, takeUntil, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { NODE_ENDPOINT } from "../settings";
import {
  profileSuccess,
  START_POLLING_PROFILE,
  STOP_POLLING_PROFILE
} from "../actions/user";
import { timer, merge } from "rxjs";
import { fetchTxs, startTxsPolling } from "../actions/tx";

// epic
const fetchProfileEpic = (action$, state$) =>
  action$.pipe(
    ofType(START_POLLING_PROFILE),

    map(action => action.payload),

    flatMap(address =>
      timer(0, 20000).pipe(
        takeUntil(action$.pipe(ofType(STOP_POLLING_PROFILE))),
        flatMap(() =>
          ajax
            .getJSON(`${NODE_ENDPOINT}/ouroboros/profile/${address}/ouro2`)
            .pipe(
              filter(
                response => response.balance !== state$.value.Profile.balance
              ),
              mergeMap(response => [
                profileSuccess(response),
                startTxsPolling(address)
              ])
            )
        )
      )
    )
  );

export default fetchProfileEpic;