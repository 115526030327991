import React, { Component } from 'react';
import { 
	Table, 
	Button,
	Modal,
	ModalBody
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';

class ProposalViewPage extends Component {
	
	state = {
		voteModalOpen: false,
		voteStep: 0,
		voteOption: null
	};

	toggleVoteModal = () => {
		this.setState({
			voteModalOpen: !this.state.voteModalOpen,
			voteStep: 0,
			voteOption: null
		});
	};

	setVoteStep = step => {
		if (step >= 0) {
			this.setState({
				voteStep: step
			});
		}
	};

	chooseVoteOption = option => {
		this.setState({
			voteOption: option
		});
	};

	getVoteStepView = step => {
		const { voteOption } = this.state;

		let renderStepView = null;

		switch (step) {
			case 0:
				renderStepView = (
					<div className="vote-options">
						<div 
							className={`vote-option ${voteOption === 'yes' ? 'active' : ''}`}
							onClick={() => this.chooseVoteOption('yes')}
						>
							Yes
						</div>

						<div 
							className={`vote-option ${voteOption === 'no' ? 'active' : ''}`}
							onClick={() => this.chooseVoteOption('no')}
						>
							No
						</div>

						<div 
							className={`vote-option ${voteOption === 'no-with-veto' ? 'active' : ''}`}
							onClick={() => this.chooseVoteOption('no-with-veto')}
						>
							No With Veto
						</div>

						<div 
							className={`vote-option ${voteOption === 'abstain' ? 'active' : ''}`}
							onClick={() => this.chooseVoteOption('abstain')}
						>
							Abstain
						</div>
					</div>
				);

				break;

			case 1:
				renderStepView = (
					<Table dark striped>
						<tbody>
							<tr>
								<td>Network Fee</td>
								<td>0.000000 ATOM</td>
							</tr>

							<tr>
								<td>Total</td>
								<td>0.000000 ATOM</td>
							</tr>
						</tbody>
					</Table>
				);

				break;
		}

		return renderStepView;
	};

	render () {
		const { 
			voteModalOpen, 
			voteStep, 
			voteOption 
		} = this.state;
		const { user } = this.props;

		return (
			<div id="box-3-w">
                <div id="box-3">

                	<div id="top-w">
                        <div id="top">
                            <h2>Добро пожаловать в Ouroboros Wallet</h2>
                            <p>Ваш кошелек: {user.address}</p>

                            <Link className="log-out" data-cy="logout-link" to="/logout"><Translate id="menu.logout" /></Link>
                            
                            <div class="clear"></div>
                        </div>
                    </div>

                    <section className="proposal-view-page mt-4">
						<Modal className="vote-modal" isOpen={voteModalOpen} toggle={() => this.toggleVoteModal()}>
							<ModalBody>
								<div className="vote-block">
									<div className="steps-list">
										<div className={`step-item ${voteStep === 0 ? 'active' : ''}`}>
											<div className="step-point">1</div>
											<div className="step-descr">Details</div>
										</div>

										<div className={`step-item ${voteStep === 1 ? 'active' : ''}`}>
											<div className="step-point">2</div>
											<div className="step-descr">Fees</div>
										</div>
									</div>

									{this.getVoteStepView(voteStep)}

									<div className="vote-actions">
										<Button color="secondary" size="lg" onClick={() => this.toggleVoteModal()}>Отмена</Button>
										
										{voteStep === 0 && <Button className="ml-2" color="primary" size="lg" onClick={() => this.setVoteStep(1)}>Далее</Button>}
										
										{voteStep === 1 && <Button className="ml-2" color="success" size="lg" onClick={() => this.toggleVoteModal()}>Подтвердить</Button>}
									</div>
								</div>
							</ModalBody>
						</Modal>

						<div className="proposal-view">
							<Button outline color="danger" size="xs">VOTING PERIOD</Button>

							<h1 className="proposal-title">Cosmos Governance Working Group - Q1 2020</h1>

							<div className="proposal-author">Proposed by <span className="author">cosmos...3uwj</span></div>

							<div className="proposal-actions">
								<Button color="primary" size="lg" onClick={() => this.toggleVoteModal()}>Голосовать</Button>
							</div>

							<div className="proposal-descr">
								<p>Cosmos Governance Working Group - Q1 2020 funding</p>
								<p>Full proposal: https://ipfs.io/ipfs/QmSMGEoY2dfxADPfgoAsJxjjC6hwpSNx1dXAqePiCEMCbY</p>
								<p>Amount to spend from the community pool: 5250 ATOMs</p>
								<p>Timeline: Q1 2020</p>
								<p>Deliverables:</p>

								<ul>
									<li>1. A governance working group community & charter</li>
									<li>2. A template for community spend proposals</li>
									<li>3. A best-practices document for community spend proposals</li>
									<li>4. An educational wiki for the Cosmos Hub parameters</li>
									<li>5. A best-practices document for parameter changes</li>
									<li>6. Monthly governance working group community calls (three)</li>
									<li>7. Monthly GWG articles (three)</li>
									<li>8. One Q2 2020 GWG recommendations article</li>
								</ul>

								<p>Beyond the milestones, Gavin will lead the GWG to engage in and answer governance-related questions on the Cosmos Discourse forum, Twitter, the private Cosmos VIP Telegram channel, and the Cosmos subreddit. The GWG will engage with stake-holders to lower the barriers to governance participation with the aim of empowering the Cosmos Hub’s stakeholders. The GWG will use this engagement to guide recommendations for future GWG planning.</p>
								<p>We’re concerned that without establishing community standards, processes, and driving decentralized delegator-based participation, the Cosmos Hub governance mechanism could be co-opted by a centralized power. As governance functionality develops, potential participants will need to understand how to assess proposals by knowing what to pay attention to.</p>
								<p>_Future_At the end of Q1, we’ll publish recommendations for the future of the Cosmos GWG, and ideally we’ll be prepared to submit a proposal based upon those recommendations for Q2 2020. We plan to continue our work in blockchain governance, regardless of whether the Hub passes our proposals.</p>

								<Table dark className="mt-5" striped>
									<tbody>
										<tr>
											<td>Total Vote Count</td>
											<td>75.02%/136 983 013</td>
										</tr>

										<tr>
											<td>Yes</td>
											<td>91.62%/125 56 108</td>
										</tr>

										<tr>
											<td>No</td>
											<td>3.95%/5 415 181</td>
										</tr>

										<tr>
											<td>Veto</td>
											<td>0.00%/1 204</td>
										</tr>

										<tr>
											<td>Abstain</td>
											<td>4.42%/6 060 520</td>
										</tr>
									</tbody>
								</Table>

								<Table dark className="mt-5" striped>
									<tbody>
										<tr>
											<td>Proposal ID</td>
											<td>23</td>
										</tr>

										<tr>
											<td>Submitted</td>
											<td>January 15th 2020, 09:51</td>
										</tr>

										<tr>
											<td>Voting Period Start Date</td>
											<td>January 15th 2020, 22:16</td>
										</tr>

										<tr>
											<td>Voting Period End Date</td>
											<td>January 29th 2020, 22:16/in 2 days</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</section>

                </div>
            </div>
		);
	}
}

export default connect(
	state => ({
		user: state.User
	}),
	null
)(withLocalize(ProposalViewPage));
