import {
    SET_ACTIVE_WALLET,
    GET_WALLETS_LIST,
} from "../actions/wallets";

export const INITIAL_STATE = {
    active_wallet: "",
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ACTIVE_WALLET:
            return {
                ...state,
                active_wallet: action.data
            }
    }
    return state;
}