import "../../views/styles/Login.scss";
import ReactCountryFlag from "react-country-flag";
import React, { useEffect } from "react";

const ChooseLang = ({ languages, setActiveLanguage, activeLanguage }) => {
  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setActiveLanguage(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", "en");
      setActiveLanguage(localStorage.getItem("lang"));
    }
  }, []);

  return (
    <div className="choose-lang">
      {languages.map((language) => {
        return (
          <a
            key={language.code}
            className={`${
              activeLanguage === language.code ? "lang-active" : ""
            }`}
            onClick={() => {
              localStorage.setItem("lang", language.code);
              setActiveLanguage(language.code);
            }}
          >
            <div style={{ display: "flex" }}>
              <ReactCountryFlag
                style={{ marginRight: 5 }}
                countryCode={language.code === "en" ? "gb" : language.code}
                svg
              />

              {language.code.toUpperCase()}
            </div>
          </a>
        );
      })}
    </div>
  );
};
export default ChooseLang;
