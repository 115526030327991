import { API_ENDPOINT, NODE_ENDPOINT_HOT } from "../settings";
import { txSendError, txSendPending, txSendSuccess } from "../actions/tx";
import { logout, stopPollingProfile } from "../actions/user";

// export default function sendTxAction(to, amount, pin_code) {
//   return (dispatch) => {
//     const token = localStorage.token;

//     if (!token) {
//       return;
//     }

//     // Мы в процессе загрузки данных
//     dispatch(txSendPending());

//     fetch(API_ENDPOINT + "/send", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify({
//         to: to,
//         amount: amount,
//         pin: pin_code,
//       }),
//     })
//       .then((res) => {
//         if (res.status === 401) {
//           dispatch(logout());
//           dispatch(stopPollingProfile());
//         }

//         return res.json();
//       })
//       .then((res) => {
//         if (res.ok === false) {
//           return dispatch(txSendError(res.message));
//         }

//         return dispatch(txSendSuccess(res.data));
//       })
//       .catch((error) => {
//         if (error.status === 401) {
//           dispatch(logout());
//           dispatch(stopPollingProfile());
//         }
//         if (error.status >= 500 && !error.message) {
//           error.message = "error.server_wrong";
//         }

//         dispatch(txSendError(error));
//       });
//   };
// }


export default function sendTxAction(type, address, pin_code,amount,symbol) {
  return (dispatch) => {
    const token = localStorage.token;

    if (!token) {
      return;
    }
    const data = {
    type: "transfer",
    address,
      pin:pin_code,
      amount: {
      "symbol": symbol,
      "amount": amount
      }
      }

    // Мы в процессе загрузки данных
    dispatch(txSendPending());
    
    fetch(NODE_ENDPOINT_HOT + "/txs" , {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ` JWT  ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }

        return res.json();
      })
      .then((res) => {
       
        if (res?.errors?.[0]?.pin ==='wrong_value') {
          return dispatch(txSendError('error.wrong_pin'));
        }
        
        if(!res?.txhash){
          return dispatch(txSendError('transaction.send_error'));
        }

        return dispatch(txSendSuccess(res));
      })
      // .then((_)=>dispatch(getProfileActionNew()))
      .catch((error) => {
        if (error.status === 401) {
          dispatch(logout());
          dispatch(stopPollingProfile());
        }
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }

        dispatch(txSendError(error));
      });
  };
}