import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Table, Button } from 'reactstrap';
import EditWallet from '../components/Modals/EditWallet';
import RemoveWallet from '../components/Modals/RemoveWallet';
import { Translate, withLocalize } from 'react-localize-redux';
import AddWallet from '../components/Modals/AddWallet';
import { setActiveWallet } from '../actions/wallets';

class Wallets extends Component {
    constructor(props) {
        super(props);
        this.setActiveWalletHandler = this.setActiveWalletHandler.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleRemoveModal = this.toggleRemoveModal.bind(this);
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.removeIconClickHandler = this.removeIconClickHandler.bind(this);
        this.editIconClickHandler = this.editIconClickHandler.bind(this);
    }
    state = {
        editModalVisible: false,
        removeModalVisible: false,
        addModalVisible: false,
        selectedForEditOrDeleteWallet: "",
        selectedForRemoveWalletTitle: "",
    }

    setActiveWalletHandler = (address) => {
        this.props.dispatch(setActiveWallet(address));
    }

    toggleEditModal = () => {
        this.setState((prevState) => {
            return {editModalVisible: !prevState.editModalVisible}
        })
    }

    toggleRemoveModal = () => {
        this.setState((prevState) => {
            return {removeModalVisible: !prevState.removeModalVisible}
        })
    }

    toggleAddModal = () => {
        this.setState((prevState) => {
            return {addModalVisible: !prevState.addModalVisible}
        })
    }

    removeIconClickHandler = (walletAddress, walletTitle) => {
        this.setState({
            selectedForEditOrDeleteWallet: walletAddress,
            selectedForRemoveWalletTitle: walletTitle
        }, () => {
            this.toggleRemoveModal();
        })
    }

    editIconClickHandler = (walletAddress) => {
        this.setState({selectedForEditOrDeleteWallet: walletAddress}, () => {
            this.toggleEditModal();
        })
    }
    
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center justify-content-between">
                            <div className="col">
                                <h4 className="page-title"><Translate id="wallets.wallets"/></h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active"><Translate id="wallets.wallet_list"/></li>
                                </ol>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <Button onClick={this.toggleAddModal}>
                                    <Translate id="wallets.add_wallet" />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-fullheight">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between mb-4">
                                        <Table responsive hover>
                                            <thead>
                                                <tr>
                                                    <th><Translate id="wallets.wallet_name"/></th>
                                                    <th><Translate id="wallets.adress"/></th>
                                                    <th><Translate id="wallets.balance"/></th>
                                                    <th><Translate id="wallets.edit" /></th>
                                                    <th><Translate id="wallets.remove" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.wallets.map((wallet, index) =>
                                                        <tr key={index}>
                                                            <td>{wallet.title}</td>
                                                            <td
                                                                className="wallets__adress-column-item"
                                                                onClick={() => this.setActiveWalletHandler(wallet.address)}
                                                            >{wallet.address}</td>
                                                            <td>{wallet.balance}</td>
                                                            <td><i className="fas fa-edit" onClick={() => this.editIconClickHandler(wallet.address)}></i></td>
                                                            <td><i className="fas fa-trash" onClick={() => this.removeIconClickHandler(wallet.address, wallet.title)}></i></td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EditWallet
                    isOpen={this.state.editModalVisible}
                    toggle={this.toggleEditModal}
                    address={this.state.selectedForEditOrDeleteWallet}
                />
                <RemoveWallet
                    isOpen={this.state.removeModalVisible}
                    toggle={this.toggleRemoveModal}
                    address={this.state.selectedForEditOrDeleteWallet}
                    title={this.state.selectedForRemoveWalletTitle}
                />
                <AddWallet
                    isOpen={this.state.addModalVisible}
                    toggle={this.toggleAddModal}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        wallets: state.User.wallets,
    }
};


export default connect(mapStateToProps)(withLocalize(Wallets));
