// Включаем двухфакторку
import {accountUpdatePending, FAEnabled, FAError, logout, stopPollingProfile, updateToken} from "../actions/user";
import {API_ENDPOINT} from "../settings";

export default function enableFAAction(fa) {
    return dispatch => {
        const token = localStorage.token;

        if (!token) {
            return;
        }

        dispatch(accountUpdatePending());

        fetch(API_ENDPOINT + '/2fa/enable', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({'fa': fa})
        })
            .then(res => {
                if (res.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }

                return res.json();
            })
            .then(res => {
                if (res.ok === false) {
                    return dispatch(FAError(res.message));
                }

                if (res.hasOwnProperty('updated_token') && res.updated_token) {
                    dispatch(updateToken(res.updated_token));
                }

                return dispatch(FAEnabled());
            })
            .catch(error => {
                if (error.status >= 500 && !error.message) {
                    error.message = "error.server_wrong";
                  }
                dispatch(FAError(error));
            });
    }
}