import React from 'react';
import {Line} from 'react-chartjs-2';

const initialState = {
    labels: [
        '7 февр. 2020',
        '5 февр. 2020',
        '6 февр. 2020',
        '7 февр. 2020',
        '8 февр. 2020',
        '9 февр. 2020',
        '10 февр. 2020'
    ],
    datasets: [
        {
            legend: {
                display: false
            },
            fill: true,
            backgroundColor: '#262952',
            borderColor: '#554DF0',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

export default class Graph extends React.Component {
    componentWillMount() {
        this.setState(initialState);
    }

    componentDidMount() {
        var _this = this;

        var oldDataSet = _this.state.datasets[0];
        var newData = [];

        for (var x = 0; x < _this.state.labels.length; x++) {
            newData.push(Math.floor(Math.random() * 100));
        }

        var newDataSet = {
            ...oldDataSet
        };

        newDataSet.data = newData;

        var newState = {
            ...initialState,
            datasets: [newDataSet]
        };

        _this.setState(newState);
    }

    render() {
        const {history} = this.props;

        console.log(this.props);
        console.log(history);

        const labels = [];
        const items = [];

        history['paramined'].map((item) => {
            labels.push(item[0]);
            items.push(item[1]);
        });

        return (
            <Line
                options={{
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }]
                    }
                }}
                height={278}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            legend: {
                                display: false
                            },
                            fill: true,
                            backgroundColor: '#262952',
                            borderColor: '#554DF0',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: items
                        }
                    ]
                }}
            />
        );
    }
}
