import {
    CHANGE_LANGUAGE, REVERSE_THEME
} from "../actions/local";
import {THEME_DARK, THEME_LIGHT} from "../settings";

export const INITIAL_STATE = {
    lang: "",
    theme: THEME_DARK
};

export default function (state = INITIAL_STATE, action) {
    if (action.type === CHANGE_LANGUAGE) {
        return {
            ...state,
            lang: action.data,
        };
    }
   if (action.type === REVERSE_THEME) {
        return {
            ...state,
            theme: state.theme === THEME_LIGHT ? THEME_DARK : THEME_LIGHT,
        };
    }

    return state;
}
