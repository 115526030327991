import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

class ReceiveTransaction extends Component {
    render() {
        const {isOpen, onResetReinvest} = this.props;

        return (
            <Modal isOpen={isOpen}>
                <ModalHeader toggle={onResetReinvest}>
                    <Translate id="paramining.reinvest_request_header"/>
                </ModalHeader>
                <ModalBody>
                    <div className="modal-body text-center">
                        <div className="py-4">
                            <i className="ion ion-ios-checkmark-circle-outline display-4 text-success"></i>

                            <h5 className="text-primary mt-4"><Translate id="transaction.yay"/></h5>
                            <p className="text-muted"><Translate id="paramining.reinvest_request_body_success"/></p>
                        </div>

                        <div className="mt-4">
                            <button type="button" onClick={onResetReinvest} className="btn btn-primary">
                                <Translate id="common.close"/>
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default withLocalize(ReceiveTransaction);
