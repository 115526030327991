// Я УЧАСТВУЮ в оконкурсе
import {
    logout,
    participateSuccess, profileSuccess,
    stopPollingProfile
} from "../actions/user";

import {API_ENDPOINT} from "../settings";

export default function participateAction() {
    return (dispatch, getState) => {
        const token = localStorage.token;


        fetch(API_ENDPOINT + '/competition/participate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }

                return res.json();
            })
            .then(res => {
                dispatch(participateSuccess(res.data));

                return res;
            })
            .catch(error => {
                console.log(error);
            });
    }
}
