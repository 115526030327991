const DECIMALS = 1000000;

// Конвертим монетки в одинаковый формат
export default function convertCoins (amount) {
    if (!amount) {
        return 0;
    }

    const asInt = parseInt(amount, 10) / DECIMALS;

    return asInt;
}

export function convertCoinsCeil (amount) {
    if (!amount) {
        return 0;
    }

    const asInt = parseInt(amount, 10) / DECIMALS;

    return Math.ceil(asInt);
}

export function convertToUsd(amount, rate) {
    if (rate === undefined) {
        rate = 0.11;
    }

    if (!amount) {
        return 0;
    }

    return Math.round(parseInt(amount) * rate) / DECIMALS;
}

export function convertFloatToUsd(amount, rate) {
    if (rate === undefined) {
        rate = 0.11;
    }

    if (!amount) {
        return 0;
    }

    const inUsd = amount * rate;

    return Math.round(inUsd * DECIMALS) / DECIMALS;
}

export function formatCoins (amount) {
    if (!amount) {
        return 0;
    }

    return +parseFloat(amount).toFixed(6);
}

export function convertFormatCoinsToUsd(amount, rate) {
    if (rate === undefined) {
        rate = 0.11;
    }

    if (!amount) {
        return 0;
    }

    return +parseFloat(amount * rate).toFixed(6);
}