import {API_ENDPOINT} from "../settings";
import {logout, stopPollingProfile} from "../actions/user";
import {undelegateSuccess} from "../actions/validators";


export default function sendUndelegateAction(validator, amount) {
    return dispatch => {
        const token = localStorage.token;

        if (!token) {
            return;
        }

        fetch(API_ENDPOINT + '/delegation/undelegate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                validator,
                amount
            })
        })
            .then(res => {
                if (res.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }

                return res.json();
            })
            .then(res => {
                if (res.ok === false) {
                    return false;
                }

                return dispatch(undelegateSuccess(res));
            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }
                

            });
    }
}
