import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logout, stopPollingProfile} from "../actions/user";

class Logout extends Component {
    componentDidMount() {
        this.props.dispatch(logout());
        this.props.dispatch(stopPollingProfile());
    }

    render() {
        this.props.history.push("/");

        return (
            <div>Redirecting...</div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.User,
});


export default connect(mapStateToProps)(Logout);