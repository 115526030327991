// Вытаскиваем данные аккаунта - email, адрес, ключ, баланс, т.д.
import {accountError, accountPending, accountSuccess, logout, stopPollingProfile} from "../actions/user";
import {API_ENDPOINT} from "../settings";
import {COLD_WALLET_ACCOUNT_TYPE} from '../constants/common';

export default function fetchAccountAction() {
    return (dispatch, getState) => {
        const token = localStorage.token;
        const {User: {account_type}} = getState();

        if (!token || account_type === COLD_WALLET_ACCOUNT_TYPE) {
            return;
        }

        // Мы в процессе загрузки данных
        dispatch(accountPending());

        fetch(API_ENDPOINT + '/profile', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }


                return res.json();
            })
            .then(res => {
                if (res.error) {
                    return dispatch(accountError(res.error));
                }

                dispatch(accountSuccess(res.data));

                return res;
            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logout());
                    dispatch(stopPollingProfile());
                }
                if (error.status >= 500 && !error.message) {
                    error.message = "error.server_wrong";
                  }
                dispatch(accountError(error));
            });
    }
}
