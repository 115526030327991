import {ofType} from 'redux-observable';
import {flatMap, mergeMap, map, takeUntil, filter} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {API_ENDPOINT} from "../settings";
import {timer} from 'rxjs'
import {rateUpdateSuccess, START_POLLING_INDICATORS, STOP_POLLING_INDICATORS} from "../actions/indicators";

// epic
const fetchIndicatorsEpic = (action$, state$) => action$.pipe(
    ofType(START_POLLING_INDICATORS),

    map(action => action.payload),

    flatMap(address =>
        timer(0, 20000).pipe(
            takeUntil(action$.pipe(ofType(STOP_POLLING_INDICATORS))),
            flatMap(() =>
                ajax.getJSON(`${API_ENDPOINT}/indicators`).pipe(
                    filter((response) => response.data.rate !== state$.value.Indicators.rate || response.data.maintenance !== state$.value.Indicators.maintenance),
                    mergeMap((response) => [rateUpdateSuccess(response.data)]),
                ),
            )
        ),
    )
);

export default fetchIndicatorsEpic;