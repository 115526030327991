import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { 
	Button, 
	Modal, 
	ModalBody,
	FormGroup,
	Input, 
	Label
} from 'reactstrap';
import {Translate, withLocalize} from "react-localize-redux";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const WEEKDAYS_LONG = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
];

const WEEKDAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'];

const proposals = [
	{
		id: 8956,
		label: {
			type: 'danger',
			value: 'VOTING PERIOD'
		},
		title: 'Cosmos Hub 3 Upgrade Proposal E',
		description: 'Cosmos Governance Working Group - Q1 2020 funding Community-spend proposal submitted by Gavin Birch (https://twitter.com/Ether_Gavin) of Figment Networks (https://figment.network) -=-=- Full propos…'
	},
	{
		id: 8956,
		label: {
			type: 'success',
			value: 'PASSED'
		},
		title: 'Cosmos Hub 3 Upgrade Proposal E',
		description: 'Cosmos Governance Working Group - Q1 2020 funding Community-spend proposal submitted by Gavin Birch (https://twitter.com/Ether_Gavin) of Figment Networks (https://figment.network) -=-=- Full propos…'
	},
	{
		id: 8956,
		label: {
			type: 'success',
			value: 'PASSED'
		},
		title: 'Cosmos Hub 3 Upgrade Proposal E',
		description: 'Cosmos Governance Working Group - Q1 2020 funding Community-spend proposal submitted by Gavin Birch (https://twitter.com/Ether_Gavin) of Figment Networks (https://figment.network) -=-=- Full propos…'
	},
	{
		id: 8956,
		label: {
			type: 'warning',
			value: 'IN REVIEW'
		},
		title: 'Cosmos Hub 3 Upgrade Proposal E',
		description: 'Cosmos Governance Working Group - Q1 2020 funding Community-spend proposal submitted by Gavin Birch (https://twitter.com/Ether_Gavin) of Figment Networks (https://figment.network) -=-=- Full propos…'
	},
	{
		id: 8956,
		label: {
			type: 'warning',
			value: 'IN REVIEW'
		},
		title: 'Cosmos Hub 3 Upgrade Proposal E',
		description: 'Cosmos Governance Working Group - Q1 2020 funding Community-spend proposal submitted by Gavin Birch (https://twitter.com/Ether_Gavin) of Figment Networks (https://figment.network) -=-=- Full propos…'
	}
];

class ProposalsListPage extends Component {

	state = {
		addProposalStep: 0,
		addProposalModalOpen: false,
		addProposalTitle: '',
		addProposalDescr: '',
		dateFilterFrom: moment(new Date()).subtract(7, 'day'),
		dateFilterTo: moment(new Date()),
		dateInputValue: moment(new Date()).subtract(7, 'day').format('DD-MM-YYYY') + ' / ' + moment(new Date()).format('DD-MM-YYYY')
	};

	toggleAddProposalModal = () => {
		this.setState({
			addProposalModalOpen: !this.state.addProposalModalOpen,
			addProposalTitle: '',
			addProposalDescr: '',
			addProposalDate: null,
			addProposalDuty: '',
			addProposalComission: ''
		});
	};

	setAddProposalStep = step => {
		if (step >= 0) {
			this.setState({
				addProposalStep: step
			});
		}
	};

	addProposalTextChange = (name, value) => {
		this.setState({
			[name]: value
		});
	};

	dateFilterChange = (e, picker) => {
		this.setState({
			dateFilterFrom: picker.startDate,
			dateFilterTo: picker.endDate,
			dateInputValue: moment(picker.startDate).format('DD-MM-YYYY') + ' / ' + moment(picker.endDate).format('DD-MM-YYYY'),
		})
	}

	getAddProposalView = step => {
		const { 
			addProposalStep, 
			addProposalTitle,
			addProposalDescr,
			addProposalDuty,
			addProposalComission,
			dateFilterFrom,
			dateFilterTo,
			dateInputValue
		} = this.state;

		let renderStepView = null;

		switch (step) {
			case 0:
				renderStepView = (
					<div className="add-proposal-form">
						<FormGroup>
							<Label for="addProposalTitle">Заголовок</Label>

							<Input 
								id="addProposalTitle" 
								type="text"
								value={addProposalTitle}
								onChange={e => this.addProposalTextChange('addProposalTitle', e.target.value)}
							/>
						</FormGroup>

						<FormGroup>
							<Label for="addProposalDescr">Описание</Label>

							<Input 
								id="addProposalDescr" 
								type="textarea"
								value={addProposalDescr}
								onChange={e => this.addProposalTextChange('addProposalDescr', e.target.value)}
							/>
						</FormGroup>

						<FormGroup>
							<DateRangePicker
					        	startDate={dateFilterFrom}
					        	endDate={dateFilterTo}
					        	onEvent={this.dateFilterChange}
					        	locale={{
					        		daysOfWeek: WEEKDAYS_SHORT,
					        		monthNames: MONTHS,
					        		applyLabel: 'Применить',
									cancelLabel: 'Сброс',
									customRangeLabel: "Своя дата"
					        	}}
					        	ranges={{
					                'Сегодня': [moment(new Date()), moment(new Date())],
					                'Вчера': [moment(new Date()).subtract(1, 'days'), moment(new Date()).subtract(1, 'days')],
					                'Последняя неделя': [moment(new Date()).subtract(6, 'days'), moment()],                   
					                'Этот месяц': [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')]                   
					            }}
					        >
						        <input 
						        	type="text" 
						        	className="form-control" 
						        	value={dateInputValue} 
						        />
						    </DateRangePicker>
					    </FormGroup>
					</div>
				);

				break;

			case 1:
				renderStepView = (
					<div className="add-proposal-form">
						<FormGroup>
							<Label for="addProposalDuty">Пошлина</Label>

							<Input 
								id="addProposalDuty" 
								type="number"
								value={addProposalDuty}
								onChange={e => this.addProposalTextChange('addProposalDuty', e.target.value)}
							/>
						</FormGroup>

						<FormGroup>
							<Label for="addProposalComission">Комиссия</Label>

							<Input 
								id="addProposalComission" 
								type="number"
								value={addProposalComission}
								onChange={e => this.addProposalTextChange('addProposalComission', e.target.value)}
							/>
						</FormGroup>
					</div>
				);

				break;
		}

		return renderStepView;
	};

	render () {
		const { addProposalStep, addProposalModalOpen } = this.state;
		const { user } = this.props;

		return (
			<div id="box-3-w">
                <div id="box-3">

                	<div id="top-w">
                        <div id="top">
                            <h2>Добро пожаловать в Ouroboros Wallet</h2>
                            <p>Ваш кошелек: {user.address}</p>

                            <Link className="log-out" data-cy="logout-link" to="/logout"><Translate id="menu.logout" /></Link>
                            
                            <div class="clear"></div>
                        </div>
                    </div>

                	<section className="proposals-page mt-4">
						<h1>Голосования</h1>

						<div className="mt-4">
							<Button color="primary" size="lg" onClick={() => this.toggleAddProposalModal()}>Создать голосование</Button>
						</div>

						<Modal className="add-proposal-modal" isOpen={addProposalModalOpen} toggle={() => this.toggleAddProposalModal()}>
							<ModalBody>
								<div className="steps-list">
									<div className={`step-item ${addProposalStep === 0 ? 'active' : ''}`}>
										<div className="step-point">1</div>
										<div className="step-descr"></div>
									</div>

									<div className={`step-item ${addProposalStep === 1 ? 'active' : ''}`}>
										<div className="step-point">2</div>
										<div className="step-descr"></div>
									</div>
								</div>

								{this.getAddProposalView(addProposalStep)}

								<div className="add-actions">
									<Button color="secondary" size="lg" onClick={() => this.toggleAddProposalModal()}>Отмена</Button>
										
									{addProposalStep === 0 && <Button className="ml-2" color="primary" size="lg" onClick={() => this.setAddProposalStep(1)}>Далее</Button>}
									
									{addProposalStep === 1 && <Button className="ml-2" color="success" size="lg" onClick={() => this.toggleAddProposalModal()}>Добавить</Button>}
								</div>
							</ModalBody>
						</Modal>

						<div className="proposals-list mt-5">
							{proposals.map(({ id, label, title, description }) => (
								<Link to={`/proposals/${id}`} className="proposal-preview-item">
									<Button outline color={label.type} size="xs">{label.value}</Button>

									<h6 className="proposal-title">{title}</h6>

									<div className="proposal-descr">
										<p>{description}</p>
										<p>{description}</p>
									</div>

									<Link to={`/proposals/${id}`} className="proposal-read-full-link">Просмотреть голосвание...</Link>
								</Link>
							))}
						</div>
					</section>

                </div>
            </div>
		);
	}
}

export default connect(
	state => ({
		user: state.User
	}),
	null
)(withLocalize(ProposalsListPage));
