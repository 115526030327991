import {
    changeEmailPending, changeEmailError, changeEmailSuccess
} from '../actions/user';
import { API_ENDPOINT } from '../settings';


export default function fetchChangeEmailToken(token) {
    return dispatch => {
        if (!token) {
            return;
        }

        dispatch(changeEmailPending());

        return fetch(API_ENDPOINT + '/change-email/validate',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'token': token,
            })
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (!res.ok) {
                    dispatch(
                      changeEmailError(
                        `error.${res.message.toLowerCase().replace(' ', '_')}`
                      )
                    );
                } else {
                    dispatch(changeEmailSuccess());
                }
            })
            .catch(error => {
                if (error.status >= 500) {
                    dispatch(changeEmailError("error.server_wrong"));
                  }
                dispatch(changeEmailError(error.message))
            })
    }
}
