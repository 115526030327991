import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate, withLocalize} from "react-localize-redux";
import fetchChangeEmailToken from "../api/fetchChangeEmailToken";
import * as actionTypes from "../store/action";
import {logout, startPollingProfile, stopPollingProfile} from "../actions/user";

class ChangeEmailPage extends Component {
    state = {
        success: false
    };

    componentDidMount() {
        console.log('Did mount');

        this.props.dispatch(fetchChangeEmailToken(this.props.match.params.token));

        if (this.props.loginpage === false) {
            this.props.dispatch({type: actionTypes.LOGINPAGE, value: true});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (!this.props.user.change_email_pending && !this.props.user.change_email_error && !this.state.success) {
            this.props.dispatch(logout());
            this.props.dispatch(stopPollingProfile());

            this.setState({success: true});
        }
    }

    render() {
        let content;

        if (this.props.user.change_email_pending) {
            content = (
                <div className="d-flex justify-content-center m-t-20 m-b-20">
                    <div className="spinner-border text-primary" role="status">
                        <span>...</span>
                    </div>
                </div>
            );
        } else if (this.props.user.change_email_error) {
            content = (
                <div className="text-center texat-danger mb-3 mt-3 font-20" style={{marginTop: 30}}><Translate
                    id={this.props.user.change_email_error}/></div>
            );
        } else {
            content = (
                <div className="text-center mb-3 mt-3 font-20" style={{marginTop: 30}}><Translate
                    id="auth.success_change_email"/></div>
            );
        }

        return (
            <>
                <div id="page">
                    <div id="box-4-w">
                        <Link to="/" className="logo">Ouroboros</Link>

                        <div className="clear"></div>

                        <div id="box-4">
                            <div className="box-4-l">
                                <h2><Translate id="auth.change_email"/></h2>
                                <p>&nbsp;</p>

                                {content}

                                <h6 className="mt-4"><Link to="/login"><Translate id="auth.login" /></Link></h6>


                                <div className="clear"></div>
                            </div>


                            <div className="box-4-r">
                                <img src={require('../assets/img/14.png')} alt=""/>

                                <p><Translate id="auth.get_access"/></p>

                                <div className="clear"></div>
                            </div>


                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loginpage: state.ui_red.loginpage,
    user: state.User
});


export default withRouter(connect(mapStateToProps)(withLocalize(ChangeEmailPage)));
