import {DELEGATIONS_UPDATE_SUCCESS, REWARDS_FETCH_SUCCESS, VALIDATORS_UPDATE_SUCCESS, RESET_REWARD} from "../actions/validators";

export const INITIAL_STATE = {
    validators: [],
    delegations: [],
    rewards: {},
    total_rewards: 0,
    total_delegated: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case VALIDATORS_UPDATE_SUCCESS:
            return {
                ...state,
                validators: action.data,
                total_delegated: action.total
            };
        case DELEGATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                delegations: action.data
            };
        case REWARDS_FETCH_SUCCESS:
            return {
                ...state,
                rewards: action.data,
                total_rewards: action.total
            };
        case RESET_REWARD:
            return {
                ...state,
                total_rewards: 0
            };
    }

    return state;
}