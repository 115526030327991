import {
  SEND_REINVEST_PENDING,
  SEND_REINVEST_SUCCESS,
  SEND_REINVEST_ERROR,
  RESET_REINVEST
} from "../actions/reinvest";

export const INITIAL_STATE = {
  error: null,
  pending: false,
  isFinished: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_REINVEST_PENDING:
      return {
        ...state,
        error: null,
        isFinished: false,
        pending: true,
      };
    case SEND_REINVEST_SUCCESS:
      return {
        ...state,
        isFinished: true,
        pending: false
      };
    case SEND_REINVEST_ERROR:
      return {
        ...state,
        isFinished: false,
        pending: false,
        error: action.error
      };
    case RESET_REINVEST:
      return {
        ...state,
        isFinished: false,
        pending: false,
        error: null
      };
    default:
      return { ...state };
  }

  return state;
}
