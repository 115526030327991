import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {COLD_WALLET_ACCOUNT_TYPE} from '../constants/common';
import {Redirect} from 'react-router-dom';

const enhance = compose(
    connect(state => ({user: state.User}))
);

export default (WrappedComponent) => (
    enhance(
        class extends PureComponent {

            render = () => {
                const {user, ...otherProps} = this.props;

                if (user.account_type === COLD_WALLET_ACCOUNT_TYPE) {
                    return <Redirect to="/"/>
                } else {
                    return <WrappedComponent {...otherProps} />;
                }
            }
        }
    )
);
