import {
    resetPasswordPadding,
    resetPasswordError,
    resetPasswordSuccess,
} from '../actions/user';
import { API_ENDPOINT } from '../settings';

export default function fetchResetPassword(token, newPassword) {

    return dispatch => {
        dispatch(resetPasswordPadding());

        return fetch(API_ENDPOINT + '/reset-password/confirm',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'token': token,
                'password': newPassword,
            })
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (!res.ok) {
                    dispatch(
                      resetPasswordError(
                        `error.${res.message.toLowerCase().replace(' ', '_')}`
                      )
                    );
                }
                return dispatch(resetPasswordSuccess(res.data));
            })
            .catch(error => {
                if (error.status >= 500) {
                    dispatch(resetPasswordError("error.server_wrong"));
                  }
                dispatch(resetPasswordError(
                  `error.something_went_wrong`
                ));
            })
    }
}
