
export const COLD_WALLET_ACCOUNT_TYPE = 'mnemo';
export const DEFAULT_ACCOUNT_TYPE = 'default';

// типы транзакций: все, исходящие, входящие, реинвест
export const ALL = 'all';
export const FROM_ADDRESS = 'sent';
export const TO_ADDRESS = 'received';
export const REINVEST = 'reinvest';
export const PARAMINING = 'paramining';
