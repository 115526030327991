import React, {Component} from 'react';
import Moment from 'react-moment';
import {Translate, withLocalize} from "react-localize-redux";
import convertCoins, {formatCoins, convertFormatCoinsToUsd} from "../../utils/coins";
import AUX from '../../hoc/Aux_';

class MainPageTransactionItem extends Component {
    state = {tx: undefined};

    componentDidMount() {
        this.setState({tx: this.props.tx});
    }

    show = () => {
        this.props.onTxShow(this.state.tx);
    };

    render() {
        const {tx, account, rate} = this.props;
        const { timestamp, sender, type } = tx;
        const isFrom = sender === account.owner;
        const isReceived = tx.recipient === account.owner;
        const fromNowDuring = 3600000;
       
      
       
        if (type === "reinvest") {
            return (
                <div className="transaction transaction-received">
                    <h3><span><Translate id="transaction.reinvest"/></span><br />
                        <Moment
                            locale={this.props.activeLanguage?.code}
                            format="DD MMM YYYY"
                            fromNowDuring={fromNowDuring}
                        >
                            {timestamp}
                        </Moment>
                    </h3>
                    <p>
                        <span>+{parseFloat(formatCoins(tx.posmined?.amount)).toFixed(2)} {tx?.amount?.symbol}</span>
                        <br />
                        {parseFloat(convertFormatCoinsToUsd(tx?.posmined?.amount, rate[tx?.amount?.symbol])).toFixed(2)}$
                    </p>
                    <div className="clear" />
                </div>
            )
        }

        if (type === "reward") {
            return (
                <div className="transaction transaction-received">
                    <h3><span><Translate id="delegation.reward"/></span><br />
                        <Moment
                            locale={this.props.activeLanguage?.code}
                            format="DD MMM YYYY"
                            fromNowDuring={fromNowDuring}
                        >
                            {timestamp}
                        </Moment>
                    </h3>
                    <p>
                        <span>+{parseFloat(formatCoins(parseInt(tx?.amount?.amount))).toFixed(2)} </span>
                        <br />
                        {parseFloat(convertFormatCoinsToUsd(parseInt(tx?.amount?.amount, rate[tx?.amount?.symbol]))).toFixed(2)}$
                    </p>
                    <div className="clear" />
                </div>
            )
        }

      if (type === "delegate") {
        return (
          <div className="transaction transaction-received">
            <h3><span><Translate id="delegation.delegation"/></span><br />
              <Moment
                locale={this.props.activeLanguage?.code}
                format="DD MMM YYYY"
                fromNowDuring={fromNowDuring}
              >
                {timestamp}
              </Moment>
            </h3>
            <p>
              <span>+{parseFloat(formatCoins(parseInt(tx?.amount?.amount))).toFixed(2)} {tx?.amount?.symbol}</span>
              <br />
              {parseFloat(convertFormatCoinsToUsd(parseInt(tx?.amount?.amount, rate[tx?.amount?.symbol]))).toFixed(2)}$
            </p>
            <div className="clear" />
          </div>
        )
      }

      if (type === "undelegate") {
        return (
          <div className="transaction transaction-sent">
            <h3><span><Translate id="delegation.delegation"/></span><br />
              <Moment
                locale={this.props.activeLanguage?.code}
                format="DD MMM YYYY"
                fromNowDuring={fromNowDuring}
              >
                {timestamp}
              </Moment>
            </h3>
            <p>
              <span>+{parseFloat(formatCoins(parseInt(tx?.amount?.amount))).toFixed(2)} {tx?.amount?.symbol}</span>
              <br />
              {parseFloat(convertFormatCoinsToUsd(parseInt(tx?.amount?.amount, rate[tx?.amount?.symbol]))).toFixed(2)}$
            </p>
            <div className="clear" />
          </div>
        )
      }

      if (type === "transfer") {
        return (
          <div className={`transaction ${!isFrom ? 'transaction-received' : 'transaction-sent'}`}>
            <h3><span>{isFrom ? <Translate id="transaction.sent"/> :
              <Translate id="transaction.received"/>}</span><br />
              <Moment
                locale={this.props.activeLanguage?.code}
                format="DD MMM YYYY"
                fromNowDuring={fromNowDuring}
              >
                {timestamp}
              </Moment>
            </h3>
            <p>
              <span>{isFrom ? "-" : "+"}{parseFloat(formatCoins(tx?.amount?.amount)).toFixed(2)} {tx.amount.symbol}</span>
              <br />
              {parseFloat(convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])).toFixed(2)}$
            </p>
            <div className="clear" />
          </div>
        )
      }

      if (type !== 'transfer' && type !== 'reward' && type !== 'reinvest' && type !== 'delegate' && type !== 'undelegate') {
        return (
          <div className={`transaction ${!isFrom ? 'transaction-received' : 'transaction-sent'}`}>
            <h3><span>{type}</span><br />
              <Moment
                locale={this.props.activeLanguage?.code}
                format="DD MMM YYYY"
                fromNowDuring={fromNowDuring}
              >
                {timestamp}
              </Moment>
            </h3>
            <p>
              <span>{isFrom ? "-" : "+"}{parseFloat(formatCoins(tx?.amount?.amount)).toFixed(2)} {tx.amout.symbol}</span>
              <br />
              {parseFloat(convertFormatCoinsToUsd(tx?.amount?.amount, rate[tx?.amount?.symbol])).toFixed(2)}$
            </p>
            <div className="clear" />
          </div>
        );
      }

      return null;

        /*if (paramining_charged !== undefined && isFrom && parseInt(paramining_charged.amount, 10) > 0) {
            return (
                <>
                    <div className="transaction transaction-received">
                        <h3><span><Translate id="transaction.paramining"/></span><br />
                            <Moment
                                locale={this.props.activeLanguage.code}
                                format="DD MMM YYYY"
                                fromNowDuring={fromNowDuring}
                            >
                                {timestamp}
                            </Moment>
                        </h3>
                        <p>
                            <span>+{convertCoins(paramining_charged.amount).toFixed(2)} OURO</span>
                            <br />
                            {convertToUsd(paramining_charged.amount, rate).toFixed(2)}$
                        </p>
                        <div className="clear" />
                    </div>
                    {transaction}
                </>
            );
        }*/
    }
}

export default withLocalize(MainPageTransactionItem);
