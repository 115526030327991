import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import './index.css';

class CopyBlock extends PureComponent {
  state = {
    wasCopied: false,
  };

  onCopyClick = (text) => async () => {
    try {
      await navigator.clipboard.writeText(text);
      this.setState({ wasCopied: true });
    } catch (e) {
      this.setState({ wasCopied: false });
    }
  };

  render() {
    const { children } = this.props;
    const { wasCopied } = this.state;
    return (
      <div
        style={{ cursor: 'pointer' }}
        className="copy-block p-3 form-group rounded text-secondary m-t-30"
        onClick={this.onCopyClick(children)}
      >
        <div>{children}</div>
        <hr />
        <div className="text-center">
          <Translate id={`common.${wasCopied ? 'copied' : 'copy_to_buffer'}`} />
        </div>
      </div>
    );
  }
}

CopyBlock.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CopyBlock;
