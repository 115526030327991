import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Translate, withLocalize } from "react-localize-redux";
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import fetchTxsAction, { fetchTransactionsAction, fetchTxsActionNew } from "../api/fetchTxs";
import Header from '../components/Header/Header';
import TransactionItem from '../components/Transaction/TransactionItem';
import {ALL, FROM_ADDRESS, REINVEST, TO_ADDRESS} from '../constants/common';
import './styles/Transactions.scss';
import MainPageTransactionItem from "../components/Transaction/MainPageTransactionItem";
import NewTransactionItem from "../components/Transaction/NewTransactionItem";
import Preloader from "../components/Preloader";
import {Link} from "react-router-dom";

moment().locale('en');

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

const WEEKDAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'];

class Transactions extends Component {
    state = {
        type: ALL,
        isPendingMoreTransactions: false
    };

    componentDidMount() {
        // this.props.dispatch(fetchTxsAction(this.props.user.address));
        // this.props.dispatch(fetchTxsAction(this.props.user.address));
        this.props.dispatch(fetchTxsActionNew(this.state.type, this.props.user.address, this.props.tx.Txs.results, 1));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tx: { pending } } = this.props;
        const { type, isPendingMoreTransactions } = this.state;
        if (prevState.type !== type) {
            this.props.dispatch(fetchTxsActionNew(type, this.props.user.address, this.props.tx.Txs.results, 1));
        }
        if (prevProps.tx.pending !== pending && isPendingMoreTransactions && !pending) {
            this.setState({isPendingMoreTransactions: false});
        }
    }

    onClickTransactionType = (type) => () => {
        this.setState({ type });
    };

    onClickShowMoreBtn = () => {
        const { type } = this.state;
        const { tx } = this.props;
        this.props.dispatch(fetchTxsActionNew(type, this.props.user.address, this.props.tx.Txs.results, ++tx.Txs.page));
        this.setState({ isPendingMoreTransactions: true});
    };


    render() {
        const { account, user, tx } = this.props;
        const { dateFilterFrom, dateFilterTo, type, isPendingMoreTransactions } = this.state;
        
        if (account === undefined) {
            return (
                <>
                    BUGA WUGA
                </>
            )
        }

        const txs = tx.Txs;
        return (
            <>
                <div id="box-5-w">
                    <div id="box-5">
                        <div id="top-w">
                            <Header address={user.address} />
                        </div>
                        <div className="box-5-w">

                            <h2><Translate id={"transaction.list"} /></h2>
                            <Link to="/" className="new-transfer-button"><Translate id={"transaction.new"} /></Link>
                            <div className="clear"></div>
                            <div className="box-5-nav-w">
                                <div
                                    onClick={this.onClickTransactionType(ALL)}
                                    className={`box-5-nav box-5-nav-all ${type === ALL ? 'box-5-nav-active' : ''}`}>
                                    <Translate id={"transaction.all"} />
                                </div>
                                <div
                                    onClick={this.onClickTransactionType(TO_ADDRESS)}
                                    className={`box-5-nav box-5-nav-received ${type === TO_ADDRESS ? 'box-5-nav-active' : ''}`}
                                >
                                    <Translate id={"transaction.received"} />
                                </div>
                                <div
                                    onClick={this.onClickTransactionType(FROM_ADDRESS)}
                                    className={`box-5-nav box-5-nav-sent ${type === FROM_ADDRESS ? 'box-5-nav-active' : ''}`}
                                >
                                    <Translate id={"transaction.sent"} />
                                </div>
                                <div
                                    onClick={this.onClickTransactionType(REINVEST)}
                                    className={`box-5-nav box-5-nav-sent ${type === REINVEST ? 'box-5-nav-active' : ''}`}
                                >
                                    <Translate id={"transaction.reinvest"} />
                                </div>

                                <div className="clear"></div>
                            </div>
                            <div className="clear"></div>
                            {tx.pending && !isPendingMoreTransactions ? (
                              <Preloader size={'bg'} />
                            ) : (
                              <div className="box-5-transaction">
                                  {txs.results ? (
                                    <>
                                        {txs.results && txs.results.map(item => (
                                          <NewTransactionItem
                                            key={item.hash}
                                            onTxShow={this.onTxShow}
                                            rate={this.props.newRate}
                                            account={account}
                                            tx={item}
                                            displayType={this.state.type}
                                          />
                                        ))}
                                    </>
                                  ) : (
                                    <div className="transactions-error-msg">
                                        {/* {JSON.stringify(tx.error)} */}
                                        no transaction
                                    </div>
                                  )}
                                  <div className="clear"></div>
                                  {isPendingMoreTransactions ? (
                                    <Preloader size={'bg'} />
                                  ) : (
                                    txs.pages > txs.page && (<div className="show-more-btn">
                                        <div
                                          onClick={this.onClickShowMoreBtn}
                                          className="box-5-nav box-5-nav-all box-5-nav-active show-more">
                                            <Translate id={"common.load_more"} />
                                        </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div className="noone">
                    <img src="/assets/images/5-2.png" alt="" />
                    <img src="/assets/images/30-2.png" alt="" />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    account: state.Profile,
    user: state.User,
    tx: state.Tx,
    rate: state.Indicators.rate,
    newRate:state.NewRate.rates
});


export default connect(mapStateToProps)(withLocalize(Transactions));
