export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const REVERSE_THEME = 'REVERSE_THEME';


export function changeLanguage(data) {
    return {
        type: CHANGE_LANGUAGE,
        data: data
    }
}

export function reverseTheme(data) {
    return {
        type: REVERSE_THEME,
    }
}
