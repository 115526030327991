import {ACCOUNT_UPDATE_SUCCESS, PROFILE_SUCCESS, LOGOUT} from "../actions/user";

export const INITIAL_STATE = {
    owner: "",
    balance: "",
    paramining: {
        paramined: "0",
        savings_coff: "0",
        paramining: {
            owner: "",
            daily_percent: "0",
            structure_coff: "0",
            paramined: "0",
            last_transaction: "",
            last_charged: ""
        },
        coins_per_time: {
            day: "0",
            hour: "0",
            minute: "0",
            second: "0"
        }
    },
    structure: {
        owner: "",
        balance: "0",
        followers: "0",
        max_level: "0"
    }
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PROFILE_SUCCESS:
            return {
                ...state,
                ...action.data,
            };
        case LOGOUT:
            return {
                ...INITIAL_STATE
            }
    }

    return state;
}