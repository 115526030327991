import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {Translate, withLocalize} from 'react-localize-redux';
import {ActionButton} from '../Buttons';

import AuthLayout from '../Layout/AuthLayout';
import BackBtn from '../Buttons/BackBtn';
import CopyBlock from '../CopyBlock';

const ACCEPT_CHECKBOX = 'accept-checkbox';
const SAVED_CHECKBOX = 'saved-checkbox';

class CreateWallet extends PureComponent {
    state = {
        [SAVED_CHECKBOX]: false,
    };

    onChange = (field) => (checkbox) => this.setState({[field]: checkbox.target.checked});

    render() {
        const {onBackClick, onAcceptClick, phrase} = this.props;
        const {
            [SAVED_CHECKBOX]: savedChecked,
        } = this.state;

        const isDisabled = !savedChecked;

        return (
            <>
                <CopyBlock>
                    {phrase}
                </CopyBlock>

                <div className="form-group p-3 rounded text-center">
                    <h5 className="font-18">
                        <Translate id="common.warning"/>
                    </h5>

                    <span className="font-16">
                        <Translate id="mnemo.warning_message"/>
                    </span>
                </div>

                <div className="custom-control form-group custom-checkbox">
                    <input
                        onChange={this.onChange(SAVED_CHECKBOX)}
                        type="checkbox"
                        className="custom-control-input"
                        id="saved-checkbox"
                    />

                    <label className="custom-control-label" htmlFor="saved-checkbox">
                        <Translate id="mnemo.i_saved_phrase"/>
                    </label>
                </div>

                <div className="btn-group-vertical w-100 mnemo-btns">
                    <ActionButton className="rounded btn-bg" disabled={isDisabled} onClick={onAcceptClick}>
                        <Translate id="common.accept"/>
                    </ActionButton>

                    <BackBtn className="rounded mt-2 btn-bg" type="button" onClick={onBackClick}/>
                </div>
            </>
        );
    }
}

CreateWallet.propTypes = {
    onAcceptClick: PropTypes.func,
    onBackClick: PropTypes.func,
    phrase: PropTypes.string.isRequired
};

CreateWallet.defaultProps = {
    onAcceptClick: () => {},
    onBackClick: () => {}
};

const enhance = compose(
    withLocalize
);

export default enhance(CreateWallet);
