import React from 'react';
import {Bar} from 'react-chartjs-2';

/*var ctx = document.querySelector('canvas').getContext("2d");

var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
gradientStroke.addColorStop(0, '#80b6f4');

gradientStroke.addColorStop(1, '#f49080');*/

const data = {
    labels: [
        '7 февр. 2020',
        '5 февр. 2020',
        '6 февр. 2020',
        '7 февр. 2020',
        '8 февр. 2020',
        '9 февр. 2020',
        '10 февр. 2020'
    ],
    datasets: [
        {
            backgroundColor: 'gradientStroke',
            borderWidth: 1,
            data: [65, 59, 80, 81, 56, 55, 40]
        }
    ]
};

export default class extends React.Component {
    render() {
        const {history} = this.props;

        const data = (canvas) => {
            const labels = [];
            const items = [];

            history['structure'].map((item) => {
                labels.push(item[0]);
                items.push(item[1]);
            });

            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, 0, 278);

            gradient.addColorStop(0, '#8B4BE7');
            gradient.addColorStop(1, '#634DED');

            return {
                backgroundColor: gradient,
                labels: labels,
                datasets: [
                    {
                        backgroundColor: gradient,
                        borderWidth: 1,
                        data: items
                    }
                ]
            }
        };

        return (
            <Bar
                data={data}
                width={100}
                height={278}
                options={{
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }]
                    }
                }}
            />
        );
    }
};
