import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormControl from '@material-ui/core/FormControl'
import { setSelectedCoin } from '../../actions/tx'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import { COLD_WALLET_ACCOUNT_TYPE } from '../../constants/common.js'

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),

    minWidth: 70

  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }

}))

function SelectCoin (props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  // const [coin, setCoin] = React.useState('OURO');
  const selectedCoin = useSelector((state) => state.Tx.selectedCoin)

  const availableCoins = ['OURO']

  // If we have any balances, we can add them to the list of available coins
  props.balances.map((balance) => {
    if (balance.denom.toUpperCase() == 'SPUT') {
      availableCoins.push(balance.denom.toUpperCase())
    }

    if (balance.denom.toUpperCase() == 'OLD_OURO') {
      availableCoins.push('OLD OURO')
    }
  })

  console.log('MY PROPS', props)
  console.log('MY COINS', availableCoins)

  const handleChange = (event) => {
    event.preventDefault()
    dispatch(setSelectedCoin(event.target.value))
  }
  return (
    <span>
      <FormControl className={classes.formControl}>

        <NativeSelect

          //  className={classes.color}
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={selectedCoin}
          onChange={handleChange}
          style={{ backgroundColor: '#293039' }}
        >
          {
            availableCoins.map((coin) => {
              return <option style={{ color: 'grey' }} value={coin.replace(' ', '_')}>{coin}</option>
            })
          }
        </NativeSelect>
      </FormControl>

    </span>
  )
}

export default connect()(withLocalize(SelectCoin))