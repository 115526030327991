import React, {Component} from 'react';
import Moment from 'react-moment';
import {Translate, withLocalize} from "react-localize-redux";
import {convertFloatToUsd, convertToUsd} from "../../utils/coins";

class TransactionItem extends Component {
    render() {
        const { item } = this.props;
        const fromNowDuring = 3600000;
        

        const amount = item.type === 'reinvest' ? item.paramining : item.amount;

        return (
            <div className={`box-5 box-5-${item.type === 'send' || item.type === 'reinvest' ? 'received' : 'sent'}`}>
                <h3>{item.type == 'reinvest' ? <Translate id={"transaction.reinvest"} /> : item.type === 'send' ? <Translate id={"transaction.received"} /> : <Translate id={"transaction.sent"} />} <span>#{item.id}</span></h3>
                <div className="clear"></div>
                <p className="p5-1"><span>{amount} OURO</span><br />{convertFloatToUsd(amount, this.props.rate)}$</p>
                <p className="p5-2"><span>
                    <Moment
                        locale={this.props.activeLanguage.code}
                        format="DD MMM YYYY"
                        fromNowDuring={fromNowDuring}
                    >
                            {item.created_at}
                        </Moment></span><br /><Translate id={"transaction.datetime"} /></p>
                <div className="clear"></div>
                <p className="p5-3"><Translate id={"transaction.sender"} />:<br /><span>{item.from_address}</span>
                </p>
                <div className="clear"></div>
            </div>
        )
    }
}

export default withLocalize(TransactionItem);
