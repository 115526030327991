import React from 'react'
import ReactDOM from 'react-dom'
import { composeWithDevTools } from 'redux-devtools-extension'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import UIreducer from './store/reducers/reducer'
import User from './reducers/user'
import Profile from './reducers/profile'
import Tx from './reducers/tx'
import Indicators from './reducers/indicators'
import Local from './reducers/local'
import Wallets from './reducers/wallets'
import Validators from './reducers/validators'
import Reinvest from './reducers/reinvest'
import NewUser from './reducers/newUser'
import NewRate from './reducers/newRate'

import { LocalizeProvider } from 'react-localize-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import thunk from 'redux-thunk'
import { createEpicMiddleware } from 'redux-observable'
import fetchProfileEpic from './epics/profile'

import { Provider } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { PersistGate } from 'redux-persist/lib/integration/react'
import fetchTxsEpic from './epics/tx'
import fetchIndicatorsEpic from './epics/indicators'
import fetchnewRateEpic from './epics/newRate'

Sentry.init({
  dsn: 'https://2026460224494d4bb7f26d1d156a1cdb@o649171.ingest.sentry.io/5840201',
  integrations: new Integrations.BrowserTracing(),
})

const epicMiddleware = createEpicMiddleware()
const middleware = [
  thunk,
  //createLogger(),
  epicMiddleware,
]

const persistConfig = {
  timeout: 0,
  key: 'root',
  storage: storage,
  blacklist: ['User', 'ui_red', 'Tx', 'Indicators', 'Validators'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
}

const userPersistConfig = {
  key: 'user',
  storage: storage,
  blacklist: [
    'pending',
    'update_pending',
    'auth_error',
    'register_error',
    'fa_error',
    'fa_required',
  ],
}

const rootReducer = combineReducers({
  ui_red: UIreducer,
  User: persistReducer(userPersistConfig, User),
  Profile,
  Tx,
  Indicators,
  Local,
  Wallets,
  Validators,
  Reinvest,
  NewUser,
  NewRate
})

const pReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(pReducer, composeWithDevTools(applyMiddleware(...middleware)))
export const persistor = persistStore(store)

// Register epic middlewares here
epicMiddleware.run(fetchProfileEpic)
epicMiddleware.run(fetchTxsEpic)
epicMiddleware.run(fetchIndicatorsEpic)
epicMiddleware.run(fetchnewRateEpic)

const app = (
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <LocalizeProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalizeProvider>
    </PersistGate>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
