import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LightLoader from '../Loader/LightLoader';

import './styles/ActionsButton.css'

const ActionButton = ({
  isEnabled,
  className,
  isLoading,
  loader,
  children,
  disabled,
  ...otherProps
}) => !isLoading ? (
  <button
    disabled={disabled}
    className={classNames(
      "btn w-md waves-effect waves-light",
      { "btn-primary": !isEnabled },
      { "btn-danger": isEnabled },
      { "button-disabled": disabled },
      className
    )}
    type="button"
    {...otherProps}
  >
    {children}
  </button>
) : loader;

ActionButton.propTypes = {
  isEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  loader: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired,
};

ActionButton.defaultProps = {
  isEnabled: false,
  disabled: false,
  className: '',
  children: '',
  isLoading: false,
  loader: <LightLoader/>,
}

export default React.memo(ActionButton);
