import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import convertCoins, { convertToUsd } from "../utils/coins";
import loadProfile from "../api/fetchProfile";
import fetchTxsAction, { fetchTxsActionNew } from "../api/fetchTxs";
import { withLocalize, Translate } from "react-localize-redux";
import CountUp from "react-countup";
import MainPageTransactionList from "../components/Transaction/MainPageTransactionList";
import TransferTabs from "../components/TransferTabs/TransferTabs";
import ParaminigGraph from "../components/Graph/ParaminigGraph";
import BalanceGraph from "../components/Graph/BalanceGraph";
import Header from "../components/Header/Header";
import { noun } from "plural-ru";
import { fetchBalanceNew } from "../actions/newUser";
import loginAction from "../api/login";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balanceOURO: 0,
      balanceOldOURO: 0, // old legacy ouro
      balanceSput: 0
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchBalanceNew(this.props.user.address));
    this.intervalBalance = setInterval(() => {
      this.props.dispatch(fetchBalanceNew(this.props.user.address));
    }, [20000]);

    // this.props.dispatch (fetchBalanceNew(this.props.user.address))

    const { newBalance } = this.props.balances;
    const balanceOURO = newBalance?.find(
      b => b.amount > 0 && b.denom === "ouro"
    );
    const balanceOldOURO = newBalance?.find(
      b => b.amount > 0 && b.denom === "old_ouro"
    );
    const balanceSput = newBalance?.find(
      b => b.amount > 0 && b.denom === "sput"
    );
    this.setState({
      balanceOURO: balanceOURO?.amount,
      balanceOldOURO: balanceOldOURO?.amount,
      balanceSput: balanceSput?.amount
    });

    this.props.dispatch(loadProfile(this.props.user.address));
    this.props.dispatch(
      fetchTxsActionNew(
        "all",
        this.props.user.address,
        this.props.tx.Txs.results
      )
    );
  }

  componentWillUnmount() {
    console.log("Greeting unmounted...");
    clearInterval(this.intervalBalance);
  }

  componentDidUpdate(prevProps, prevState) {
    const { newBalance } = this.props.balances;

    const balanceOURO = newBalance?.find(
      b => b.amount > 0 && b.denom === "ouro"
    );
    const balanceOldOURO = newBalance?.find(
      b => b.amount > 0 && b.denom === "old_ouro"
    );
    const balanceSput = newBalance?.find(
      b => b.amount > 0 && b.denom === "sput"
    );

    if (
      this.state.balanceOURO !== balanceOURO?.amount ||
      this.state.balanceOldOURO !== balanceOldOURO?.amount ||
      this.state.balanceSput !== balanceSput?.amount
    ) {
      this.setState({
        balanceOURO: balanceOURO?.amount,
        balanceOldOURO: balanceOldOURO?.amount,
        balanceSput: balanceSput?.amount
      });
    }
  }

  render() {
    const { tx, rate, account, user } = this.props;
    const paramining = account.paramining;
    const perTime = account.paramining.coins_per_time;

    const start = parseInt(paramining.paramined) / 1000000;
    const end =
      (parseInt(paramining.paramined) + parseInt(perTime.day)) / 1000000;

    let paramined = parseInt(paramining.paramined);

    if (
      this.countUpRef !== undefined &&
      this.countUpRef.instance !== undefined &&
      this.countUpRef.instance.frameVal
    ) {
      paramined = this.countUpRef.instance.frameVal * 1000000;
    }

    let followersMessage = (
      <span>
        {this.props.account.structure.followers}{" "}
        {noun(
          this.props.account.structure.followers,
          "человек",
          "человека",
          "человек"
        )}
        <br /> на {this.props.account.structure.max_level}{" "}
        {noun(
          this.props.account.structure.max_level,
          "уровне",
          "уровнях",
          "уровней"
        )}
      </span>
    );

    if (this.props.activeLanguage && this.props.activeLanguage.code === "en") {
      followersMessage = (
        <span>
          {this.props.account.structure.followers}{" "}
          {noun(
            this.props.account.structure.followers,
            "follower",
            "followers",
            "followers"
          )}
          <br /> on {this.props.account.structure.max_level}{" "}
          {noun(
            this.props.account.structure.max_level,
            "level",
            "levels",
            "levels"
          )}
        </span>
      );
    }
    if (this.props.activeLanguage && this.props.activeLanguage.code === "tr") {
      followersMessage = (
        <span>
          {this.props.account.structure.max_level} seviyede
          <br /> {this.props.account.structure.followers} takipçiniz var.
        </span>
      );
    }

    const balances = [
      [this.state.balanceOURO, "OURO"],
      [this.state.balanceSput, "SPUT"]
    ];

    if (this.state.balanceOldOURO > 0) {
      balances.push([this.state.balanceOldOURO, "OLD OURO"]);
    }

    const graphMarginTop = balances.length * 46;

    return (
      <div id="box-1-w">
        <div id="box-1">
          <div className="box-1-l">
            <div id="top-w">
              <Header address={user.address} />
            </div>

            <div className="balance">
              {balances.map((balance, index) => {
                return (
                  <h2 key={index}>
                    <Translate id="wallet.balance" />
                    <br />
                    <span className="sp1-1">
                      {convertCoins(balance[0])}{" "}
                      <span className="sp1-2">{balance[1]}</span>
                    </span>
                  </h2>
                );
              })}

              <div className="graph">
                <BalanceGraph history={user.history} theme={this.props.theme} />
              </div>

              <div className="clear" />
            </div>

            <div className="paramining">
              <h2>
                <Translate id="wallet.paramining" />
                <br />
                <span
                  className="sp1-1"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "100%", display: "inline-block" }}>
                    <CountUp
                      start={start}
                      end={end}
                      duration={86400}
                      separator=" "
                      decimals={6}
                      useEasing={false}
                      decimal="."
                      suffix=""
                      ref={countUp => {
                        this.countUpRef = countUp;
                      }}
                    />
                  </div>
                  <span className="sp1-2">OURO</span>
                </span>
              </h2>

              <div
                className="graph"
                style={{ marginTop: graphMarginTop + "px" }}
              >
                <ParaminigGraph history={user.history} />
              </div>

              <div className="clear" />
            </div>

            <div className="clear" />
          </div>

          <div className="box-1-r">
            <MainPageTransactionList
              txs={tx.Txs}
              pending={tx.pending}
              rate={this.props.newRate}
              error={tx.error}
              account={account}
            />

            <div className="structure">
              <h3>
                <Translate id="wallet.structure" />
              </h3>

              <p className="str-p-1">
                <span>{convertCoins(account.structure.balance)}</span> OURO
              </p>
              <p className="str-p-2">
                <span>{convertToUsd(account.structure.balance, rate)}</span> USD
              </p>

              <p className="str-p-3">{followersMessage}</p>
              <div className="clear" />
            </div>

            <div className="clear" />
          </div>

          <div className="box-1-b">
            <TransferTabs account={account} tx={tx} user={user} />
          </div>

          <div className="clear" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.Profile,
  user: state.User,
  tx: state.Tx,
  rate: state.Indicators.rate,
  theme: state.Local.theme,
  balances: state.NewUser,
  newRate: state.NewRate.rates
});

export default connect(mapStateToProps)(withLocalize(Dashboard));