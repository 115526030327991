import React, {PureComponent} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {withLocalize, Translate} from 'react-localize-redux';
import {ActionButton} from '../Buttons';
import AuthLayout from '../Layout/AuthLayout';
import BackBtn from '../Buttons/BackBtn';

const FIRST_INPUT_FLUID = 'firstWord';
const SECOND_INPUT_FLUID = 'secondWord';

const FIRST_WORD_NUMBER = 1;
const SECOND_WORD_NUMBER = 6;

class AcceptMnemo extends PureComponent {
    state = {
        [FIRST_INPUT_FLUID]: '',
        [SECOND_INPUT_FLUID]: '',
    };

    onChange = (fluid) => (element) => this.setState({[fluid]: element.target.value.toLowerCase()});

    onSubmit = e => {
        const {phrase, onSubmit} = this.props;
        const {
            [FIRST_INPUT_FLUID]: firstWord,
            [SECOND_INPUT_FLUID]: secondWord,
        } = this.state;

        const phraseArr = phrase.split(' ');

        if (
            phraseArr[FIRST_WORD_NUMBER-1] === firstWord
            && phraseArr[SECOND_WORD_NUMBER-1] === secondWord
        ) {
            onSubmit();
        }
    };

    render() {
        const {onBackClick, onSubmit, phrase} = this.props;

        const {
            [FIRST_INPUT_FLUID]: firstWord,
            [SECOND_INPUT_FLUID]: secondWord,
        } = this.state;

        let valid = false;

        const phraseArr = phrase.split(' ');

        if (
            phraseArr[FIRST_WORD_NUMBER-1] === firstWord
            && phraseArr[SECOND_WORD_NUMBER-1] === secondWord
        ) {
            valid = true;
        }

        return (
            <>
                <div className="form-group p-3 rounded text-center" style={{marginBottom: 0}}>
                    <span className="font-16">
                        <Translate id="mnemo.accept_mnemo_description"/>
                    </span>
                </div>

                <div className="formbox mt-2">
                    <form action="/" id="form-registration" onSubmit={this.onSubmit}>
                        <Translate>
                            {({ translate }) =>
                                <input
                                    id="first-word"
                                    type="text"
                                    value={firstWord}
                                    onChange={this.onChange(FIRST_INPUT_FLUID)}
                                    placeholder={translate("common.word") + ' ' + FIRST_WORD_NUMBER}
                                />
                            }
                        </Translate>

                        <Translate>
                            {({ translate }) =>
                                <input
                                    id="second-word"
                                    type="text"
                                    value={secondWord}
                                    onChange={this.onChange(SECOND_INPUT_FLUID)}
                                    placeholder={translate("common.word") + ' ' + SECOND_WORD_NUMBER}
                                />
                            }
                        </Translate>

                        <div className="clear"></div>

                        <div className="form-group mt-4">
                            <div className="btn-group-vertical w-100 mnemo-btns">
                                <ActionButton className="rounded btn-bg" disabled={!valid} type="submit">
                                    <Translate id="common.accept"/>
                                </ActionButton>

                                <BackBtn className="rounded mt-2 btn-bg" type="button" onClick={onBackClick}>
                                    <Translate id="mnemo.get_new_mnemo"/>
                                </BackBtn>
                            </div>
                        </div>

                        <div className="clear"></div>
                    </form>
                </div>
            </>
        );
    }
}

AcceptMnemo.propTypes = {
    onBackClick: PropTypes.func,
    phrase: PropTypes.string.isRequired,
};

AcceptMnemo.defaultProps = {
    onBackClick: () => {
    },
};

const enhance = compose(
    withLocalize
);

export default enhance(AcceptMnemo);
