import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthLogo from '../Logo/AuthLogo';
import {Button, ButtonGroup} from 'reactstrap';
import {Translate} from 'react-localize-redux';
import {childrenType} from '../../constants/types';

const AuthLayout = ({children, title, description}) => {
    return (
        <div className="wrapper-page">
            <div className="card overflow-hidden account-card mx-3">

                <div className="bg-primary p-4 text-white text-center position-relative">
                    <h4 className="font-20 m-b-5">{title}</h4>

                    <p className="text-white-50 mb-4">{description}</p>

                    <AuthLogo/>
                </div>

                <div className="account-card-content">

                    <div className="account-login-type-btns-wrap">
                        <ButtonGroup>
                            <Link to="/login" className={"account-login-type-btn btn btn-primary"} color={"primary"}><Translate id="auth.email_login"/></Link>
                            <Link to="/mnemonic" className={"account-login-type-btn btn btn-primary active"} color={"primary"}><Translate id="auth.memo_login"/></Link>
                        </ButtonGroup>
                    </div>

                    {children}

                </div>

            </div>
        </div>
    );
};

AuthLayout.propTypes = {
    children: childrenType,
    title: childrenType,
    description: childrenType,
};

AuthLayout.defaultProps = {
    children: null,
    title: <Translate id="auth.welcome"/>,
    description: <Translate id="auth.get_in"/>,
};

export default AuthLayout;
