import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionTypes from "../store/action";
import {withLocalize, Translate} from "react-localize-redux";
import './styles/TechnicalWork.scss';

class TechnicalWork extends Component {

  componentDidMount() {
    this.props.dispatch({type: actionTypes.LOGINPAGE, value: true});
  }

  render() {
    return (
      <div className="technical-work">
        <div className="technical-work__text">
          <Translate id="technical_work.header" />
        </div>
      </div>
    )
  }
}


export default connect()(withLocalize(TechnicalWork));
