import React, {Component} from "react"
import {Modal, Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { withLocalize, Translate } from "react-localize-redux";
import {connect} from "react-redux";

import removeWallet from "../../api/removeWallet";

class RemoveWallet extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
        }

        this.remove = this.remove.bind(this);
    }

    remove = () => {
        this.props.dispatch(removeWallet(this.props.address, this.props.title));
        this.props.toggle();
    }
    render(){
        const {isOpen, toggle} = this.props
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <Translate id="wallets.remove_wallet" />
                </ModalHeader>
                <ModalBody>
                    <p>
                        Вы уверены что хотите удалить кошелёк? Это действие необратимо
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={this.remove}>
                        <Translate id="wallets.remove"/>
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default connect()(withLocalize(RemoveWallet));