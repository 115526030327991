import React from 'react';
import {Bar} from 'react-chartjs-2';
import {THEME_DARK} from "../../settings";

export default class extends React.Component {
    render() {
        const {history, theme} = this.props;

        const data = (canvas) => {
            const labels = [];
            const items = [];

            history['balance'].map((item) => {
                labels.push(item[0]);
                items.push(item[1]);
            });

            const ctx = canvas.getContext("2d");
            const gradient = theme === THEME_DARK ? ctx.createLinearGradient(0, 0, 0, 278) : ctx.createLinearGradient(0, 0, 0, 278);

            gradient.addColorStop(0, '#8B4BE7');
            gradient.addColorStop(1, '#634DED');

            return {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: gradient,
                        borderWidth: 0,
                        data: items
                    }
                ]
            }
        };

        return (
            <Bar
                data={data}
                width={100}
                height={278}
                options={{
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                display: false //this will remove only the label
                            }
                        }]
                    }
                }}
            />
        );
    }
};
