import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../store/action";
import register from "../api/register";
import AuthLogo from "../components/Logo/AuthLogo";
import { Translate, withLocalize } from "react-localize-redux";
import Recaptcha from "react-google-invisible-recaptcha";
import { THEME_LIGHT } from "../settings";
import { reverseTheme } from "../actions/local";
import Preloader from "../components/Preloader";

import ReactCountryFlag from "react-country-flag";
import ChooseLang from "../components/ChooseLang/ChooseLang";

class Register extends Component {
  state = {
    email: "",
    password: "",
    repeatPassword: "",
    telegram: "",
    repeatPasswordError: "",
  };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaInstance = undefined;
  }

  verifyCallback(recaptcha) {
    this.props.dispatch(
      register(
        this.state.email,
        this.state.password,
        this.state.telegram,
        recaptcha
      )
    );
  }

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: true });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.password !== this.state.repeatPassword) {
      this.setState({ repeatPasswordError: "Пароли не совпадают" });
    }

    if (
      this &&
      this.state.email &&
      this.state.password &&
      this.state.repeatPassword &&
      this.state.password === this.state.repeatPassword
    ) {
      this.recaptchaInstance.execute();
    }
  };

  onChangeField = (field) => (event) => {
    this.setState({ [field]: event.target.value });

    if (field === "repeatPassword") {
      this.setState({ repeatPasswordError: "" });
    }
  };

  render() {
   
    let errorMessage;

    if (this.props.user.register_error) {
      errorMessage = (
        <div className="text-danger" style={{ marginTop: 20 }}>
          <Translate id={this.props.user.register_error} />
        </div>
      );
    }

    if (this.props.user.address) {
      this.props.dispatch({ type: actionTypes.LOGINPAGE, value: false });

      this.props.history.push("/");

      return <div>Redirecting...</div>;
    }

    let hasError = !!this.props.user.register_error;

    const button = (
      <div className="submit">
        <button type="submit" disabled={this.props.user.pending}>
          <Translate id="auth.register" />
          {this.props.user.pending && <Preloader />}
        </button>
      </div>
    );

    return (
      <>
        <Recaptcha
          ref={(e) => (this.recaptchaInstance = e)}
          sitekey="6Lel4sAUAAAAADDOXrPbbOT6AQCYvcOzCPGIR7SQ"
          onResolved={(e) => this.verifyCallback(e)}
        />

        <div id="page">
          <div id="box-4" style={{ zIndex: 7 }}>
            <div id="box-4-w">
              <ChooseLang
                languages={this.props.languages}
                setActiveLanguage={this.props.setActiveLanguage}
                activeLanguage={
                  this.props.activeLanguage && this.props.activeLanguage.code
                }
              />

              <Link to="/" className="logo">
                Ouroboros
              </Link>
            </div>
          </div>

          <div id="box-4">
            <div className="box-4-l">
              <h2>
                <Translate id="auth.register" />
              </h2>
              <p>
                <Translate id="auth.one_step" />
              </p>

              <div className="formbox">
                <form
                  action="/"
                  id="form-registration"
                  onSubmit={this.onSubmit}
                >
                  <Translate>
                    {({ translate }) => (
                      <input
                        type="email"
                        size="50"
                        name="email"
                        className={`email ${hasError ? "error" : ""}`}
                        value={this.state.email}
                        onChange={this.onChangeField("email")}
                        placeholder={translate("auth.enter_email")}
                      />
                    )}
                  </Translate>

                  {hasError && errorMessage}

                  <Translate>
                    {({ translate }) => (
                      <input
                        type="password"
                        size="50"
                        name="password"
                        className={`password ${hasError ? "error" : ""}`}
                        value={this.state.password}
                        onChange={this.onChangeField("password")}
                        placeholder={translate("auth.enter_password")}
                      />
                    )}
                  </Translate>

                  <Translate>
                    {({ translate }) => (
                      <input
                        type="password"
                        size="50"
                        name="confirm-password"
                        className={`password ${
                          this.state.repeatPasswordError || hasError
                            ? "error"
                            : ""
                        }`}
                        value={this.state.repeatPassword}
                        onChange={this.onChangeField("repeatPassword")}
                        placeholder={translate("auth.repeat_password")}
                      />
                    )}
                  </Translate>

                  {this.state.repeatPasswordError ? (
                    <div className="text-danger" style={{ marginTop: 20 }}>
                      <Translate id="auth.passwords_dont_match" />
                    </div>
                  ) : null}

                  {button}
                </form>
              </div>

              <h6 className="mt-4">
                <Translate id="auth.already_have" />{" "}
                <Link to="/login" className={"font-500 text-primary"}>
                  <Translate id="auth.login" />
                </Link>
              </h6>
            </div>

            <div class="box-4-r">
              <img
                src={
                  this.props.theme === THEME_LIGHT
                    ? require("../assets/img/14-white.png")
                    : require("../assets/img/14.png")
                }
                alt=""
              />

              <p>
                <Translate id="auth.get_access" />
              </p>

              <div class="clear"></div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "126px" }}
          className="lang"
          onClick={() => {
            this.setState({ ...this.state, show: !this.state.show });

            document.getElementsByClassName("rodal")[0].style.display = "";
          }}
        >
          {this.props.languages.map((l) => {
            if (l.active) {
              return (
                <div className="lang__block">
                  <ReactCountryFlag
                    countryCode={l.code === "en" ? "gb" : l.code}
                    svg
                  />
                  <div>{l.code.toUpperCase()}</div>
                  {!this.state.show ? (
                    <i class="fas fa-caret-down" />
                  ) : (
                    <i class="fas fa-caret-up" />
                  )}
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginpage: state.ui_red.loginpage,
  user: state.User,
  theme: state.Local.theme,
});

export default connect(mapStateToProps)(withLocalize(Register));
