import {
  txFetchSuccess,
  txFetchError,
  txFetchPending,
  transactionsFetchError,
  transactionsFetchPending,
  transactionsFetchSuccess,
} from "../actions/tx";
import { API_ENDPOINT, NODE_ENDPOINT, NODE_ENDPOINT_NEW } from "../settings";
import { COLD_WALLET_ACCOUNT_TYPE, ALL } from "../constants/common";

const getRequestData = (totalCount) => {
  const requestData = {
    limit: 20,
    totalPages: 1,
    page: 1,
    totalCount,
  };
  if (totalCount > 20) {
    requestData.totalPages = Math.ceil(totalCount / 20);
  } else {
    requestData.totalPages = 1;
  }
  const lastPageRemains = totalCount - (requestData.totalPages - 1) * 20;
  if (lastPageRemains < 24) {
    requestData.limit =
      requestData.totalPages > 1 ? lastPageRemains + 20 : 20;
    requestData.page = Math.ceil(totalCount / requestData.limit);
  } else {
    requestData.page = requestData.totalPages;
  }
  return requestData;
};

const getCountTransactionsMessageSender = (address) => {
  return new Promise((resolve, reject) => {
    return fetch(
      NODE_ENDPOINT + "/txs?limit=1&page=1&transfer.recipient=" + address
    )
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCountTransactionsTransferRecipient = (address) => {
  return new Promise((resolve, reject) => {
    return fetch(
      NODE_ENDPOINT + "/txs?limit=1&page=1&message.sender=" + address
    )
      .then((res) => {
        resolve(res.json());
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function compare(a, b) {
  const a_height = parseInt(a.height, 10);
  const b_height = parseInt(b.height, 10);

  if (a_height < b_height) {
    return 1;
  }
  if (a_height > b_height) {
    return -1;
  }

  // Парамайнинг чарджи должны быть сверху
  if (a_height === b_height && a.tx === undefined && a.tx !== undefined) {
    if (a.tx === undefined) {
      return 1;
    }

    if (b.tx === undefined) {
      return -1;
    }
  }

  return 0;
}

// Вытаскиваем список транзакций юзера
function fetchTxsAction(address) {
  return (dispatch, getState) => {
    // Мы в процессе загрузки данных
    dispatch(txFetchPending());
    Promise.all([
      getCountTransactionsTransferRecipient(address),
      getCountTransactionsMessageSender(address),
    ])
      .then((values) => {
        const messageSenderRequestData = getRequestData(values[0].total_count);
        fetch(
          `${NODE_ENDPOINT}/txs?limit=${messageSenderRequestData.limit}&page=${messageSenderRequestData.page}&message.sender=${address}`
        )
          .then((res) => {
            return res.json();
          })
          .then((senderData) => {
            const transferRecipientRequestData = getRequestData(
              values[1].total_count
            );
            fetch(
              `${NODE_ENDPOINT}/txs?limit=${transferRecipientRequestData.limit}&page=${transferRecipientRequestData.page}&transfer.recipient=${address}`
            )
              .then((res) => res.json())
              .then((recipientData) => {
                let concated = senderData.txs.concat(recipientData.txs);

                const result = [];
                const uniqueHashes = {};

                for (let i in concated) {
                  if (uniqueHashes[concated[i].txhash] === undefined) {
                    uniqueHashes[concated[i].txhash] = true;

                    result.push(concated[i]);
                  }
                }

                result.sort(compare);

                dispatch(txFetchSuccess(result));

                return result;
              });
          })
          .catch((error) => {
            if (error.status >= 500 && !error.message) {
              error.message = "error.server_wrong";
            }
            dispatch(txFetchError(error));
          });
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(txFetchError(error));
      });
  };
}

export function fetchTxsActionNew(type, address, txs, page = 1) {
  return (dispatch) => {
    dispatch(txFetchPending());
    fetch(NODE_ENDPOINT_NEW + "/txs/"+address+"?limit=20&type=" + type + "&page=" + page, {
      method: "GET",
      // headers: {
      //   // Authorization: "JWT " + token,
      // },
    })
      .then((res) => res.json())
      .then((data) => {
        if (page > 1) {
          data.results = txs.concat(data.results);
        }
        dispatch(txFetchSuccess(data));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(txFetchError(error));
      });
  };
}

// Список транзакций по новому апи
export function fetchTransactionsAction(page, type, to, from) {
  return (dispatch, getState) => {
    // Мы в процессе загрузки данных
    dispatch(transactionsFetchPending());
    const token = localStorage.getItem("token") || "";
    const filter = type !== ALL ? type : null;
    fetch(
      API_ENDPOINT +
        `/txs/?page=${page}&to=${to}&from=${from}${
          !!filter ? "&filter=" + filter : ""
        }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((senderData) => {
        dispatch(transactionsFetchSuccess(senderData));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(transactionsFetchError(error));
      });
  };
}

export default fetchTxsAction;
