export const SEND_REINVEST_PENDING = 'SEND_REINVEST_PENDING';
export const SEND_REINVEST_SUCCESS = 'SEND_REINVEST_SUCCESS';
export const SEND_REINVEST_ERROR = 'SEND_REINVEST_ERROR';
export const RESET_REINVEST = 'RESET_REINVEST';


export function txSendReinvestPending() {
  return {
    type: SEND_REINVEST_PENDING
  }
}

export function txSendReinvestSuccess() {
  return {
    type: SEND_REINVEST_SUCCESS,
  }
}

export function txSendReinvestError(error) {
  return {
    type: SEND_REINVEST_ERROR,
    error: error
  }
}

export function resetReinvest() {
  return {
    type: RESET_REINVEST,
  }
}
