import React from 'react';
import './index.scss';

const Preloader = ({size = 'small'}) => {
  return (
    <div className={`preloader ${size === 'bg' && 'preloader_bg'}`}>
      <div className={`preloader__content ${size === 'bg' && 'preloader__content_bg'}`}>
        <div></div>
      </div>
    </div>
  )
};

export default Preloader;
