import {
  sendMnemoTxError,
  sendMnemoTxPending,
  sendMnemoTxSuccess
} from "../actions/tx";
import {
  generateAccountFromMnemonic,
  generatePushableTransaction
} from "../utils/crypto";
import * as blockchain from "../utils/blockchain";

export default function sendTxAction(user, to, amount, symbol) {
  // Replace the denom with with the blockchain notaiton
  if (symbol === "ouro") {
    symbol = "ouro2";
  } else if (symbol === "old_ouro") {
    symbol = "ouro";
  }

  return (dispatch, getState) => {
    dispatch(sendMnemoTxPending());

    const accountData = generateAccountFromMnemonic(user.mnemonic);

    blockchain.getAccountInfo(accountData.address).then(accountInfo => {
      const accountNumber = accountInfo.value.account_number;
      const sequence = accountInfo.value.sequence;

      const realAmount = (parseFloat(amount) * 1e6).toString();

      const pushableTx = generatePushableTransaction(
        accountNumber.toString(),
        sequence.toString(),
        accountData.pubKey,
        accountData.privKey,
        to,
        realAmount,
        undefined,
        symbol
      );

      // Send that tx to our blockchain
      blockchain.broadcastTx(pushableTx).then(response => {
        dispatch(sendMnemoTxSuccess(response));
      });
    });
  };
}