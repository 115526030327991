import {
  ACCOUNT_SUCCESS,
  AUTH_ERROR,
  AUTH_PENDING,
  AUTH_SUCCESS,
  CHANGE_REINVEST,
  LOGOUT,
  REGISTER_ERROR,
  REGISTER_PENDING,
  REGISTER_SUCCESS,
  FA_REQUIRED,
  FA_ENABLED,
  ACCOUNT_UPDATE_PENDING,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_SUCCESS,
  FA_DISABLED,
  FA_ERROR,
  RESET_PASSWORD_PADDING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_TOKEN_PADDING,
  RESET_PASSWORD_TOKEN_SUCCESS,
  RESET_PASSWORD_TOKEN_ERROR,
  GET_RESET_PASSWORD_LINK_PADDING,
  GET_RESET_PASSWORD_LINK_SUCCESS,
  GET_RESET_PASSWORD_LINK_ERROR,
  SET_ACCOUNT_TYPE,
  AUTH_MNEMONIC_SUCCESS,
  TOKEN_UPDATE,
  PARTICIPATE_SUCCESS,
  CHANGE_EMAIL_PENDING,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_ERROR,
} from "../actions/user";
import { DEFAULT_ACCOUNT_TYPE } from "../constants/common";
import { generateAccountFromMnemonic } from "../utils/crypto";
import {
  CREATE_NEW_WALLET,
  EDIT_WALLET,
  REMOVE_WALLET,
} from "../actions/wallets";

export const INITIAL_STATE = {
  email: "",
  address: "",
  pubkey: "",
  mnemonic: "",
  token: "",
  refresh: "",
  reinvest: 0,
  account_type: DEFAULT_ACCOUNT_TYPE,

  pin_set: false,
  is_authenticated: false,
  fa_required: false,
  pending: false,
  update_pending: false,

  telegram_confirmed: false,
  telegram_confirmation: "",
  email_confirmed: false,

  reset_password_token_error: "",
  profile_update_error: "",
  register_error: "",

  fa_error: "",
  fa_enabled: false,

  in_competition: false,
  can_participate: false,
  competition_next: 0,
  competition_place: 0,

  fa_secret: "",
  fa_qr: "",

  wallets: [],

  history: {
    balance: [],
    paramined: [],
    structure: [],
  },

  reset_password_error: "",
  change_email_error: "",
  get_reset_password_link_error: "",

  reset_password_token_padding: false,
  change_email_pending: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.data.token);

      return {
        ...state,
        ...action.data,
        is_authenticated: true,
        pending: false,
      };
    case REGISTER_PENDING:
      return {
        ...state,
        ...action.data,
        pending: true,
      };
    case AUTH_SUCCESS:
      localStorage.setItem("token", action.data.token);

      return {
        ...state,
        ...action.data,
        is_authenticated: true,
        pending: false,
      };
    case ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.data,
        pending: false,
        update_pending: false,
      };
    case ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.data,
        profile_update_error: "",
        update_pending: false,
      };
    case ACCOUNT_UPDATE_ERROR:
      return {
        ...state,
        profile_update_error: action.error,
        update_pending: false,
      };
    case ACCOUNT_UPDATE_PENDING:
      return {
        ...state,
        ...action.data,
        update_pending: true,
      };
    case AUTH_PENDING:
      return {
        ...state,
        ...action.data,
        pending: true,
      };
    case AUTH_ERROR:
      return {
        ...state,
        auth_error: action.error,
        pending: false,
      };
    case EDIT_WALLET:
      const indexOfWalletToEdit = state.wallets.findIndex(
        (wallet) => wallet.address === action.data.address
      );
      const newEdtitedArray = state.wallets;
      newEdtitedArray[indexOfWalletToEdit] = action.data;
      // WIP
      return {
        ...state,
        wallets: newEdtitedArray,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        register_error: action.error,
        pending: false,
      };
    case REMOVE_WALLET:
      const indexOfWalletToRemove = state.wallets.findIndex(
        (wallet) => wallet.address === action.data
      );
      const newArraywithRemovedElement = state.wallets;

      for (var i = 0; i < newArraywithRemovedElement.length; i++) {
        if (i === indexOfWalletToRemove) {
          newArraywithRemovedElement.splice(i, 1);
        }
      }

      return {
        ...state,
        wallets: newArraywithRemovedElement,
      };
    case LOGOUT:
      localStorage.removeItem("token");

      return INITIAL_STATE;
    case CHANGE_REINVEST:
      const userData = JSON.parse(localStorage.getItem("account"));

      userData["reinvest"] = parseFloat(action.data);

      localStorage.setItem("account", JSON.stringify(userData));

      return {
        ...state,
        ...userData,
        update_pending: false,
      };
    case CREATE_NEW_WALLET: {
      return {
        ...state,
        wallets: [...state.wallets, action.data],
      };
    }
    case FA_REQUIRED:
      return {
        ...state,
        fa_required: true,
        pending: false,
        update_pending: false,
      };
    case FA_ENABLED:
      return {
        ...state,
        fa_enabled: true,
        fa_error: "",
        pending: false,
        update_pending: false,
      };
    case FA_DISABLED:
      return {
        ...state,
        fa_enabled: false,
        fa_error: "",
        pending: false,
        update_pending: false,
      };
    case FA_ERROR:
      return {
        ...state,
        fa_error: action.error,
        pending: false,
        update_pending: false,
      };
    case RESET_PASSWORD_PADDING:
    case GET_RESET_PASSWORD_LINK_PADDING:
      return {
        ...state,
        padding: true,
      };
    case RESET_PASSWORD_SUCCESS:
    case GET_RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        padding: false,
        reset_password_error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        padding: false,
        reset_password_error: action.error,
      };
    case GET_RESET_PASSWORD_LINK_ERROR:
      return {
        ...state,
        padding: false,
        get_reset_password_link_error: action.error,
      };
    case RESET_PASSWORD_TOKEN_PADDING:
      return {
        ...state,
        reset_password_token_padding: true,
      };
    case RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        reset_password_error: "",
        reset_password_token_error: "",
        reset_password_token_padding: false,
      };
    case RESET_PASSWORD_TOKEN_ERROR:
      return {
        ...state,
        reset_password_token_error: action.error,
        reset_password_token_padding: false,
      };
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        account_type: action.payload,
      };
    case AUTH_MNEMONIC_SUCCESS:
      const account = generateAccountFromMnemonic(action.data);

      return {
        ...state,
        address: account.address,
        pubkey: account.pubKey,
        mnemonic: action.data,
        is_authenticated: true,
      };
    case TOKEN_UPDATE:
      localStorage.setItem("token", action.data);

      return {
        ...state,
        token: action.data,
      };
    case PARTICIPATE_SUCCESS:
      return {
        ...state,
        ...action.data,
        in_competition: true,
      };
    case CHANGE_EMAIL_PENDING:
      return {
        ...state,
        change_email_pending: true,
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        change_email_error: "",
        change_email_pending: false,
      };
    case CHANGE_EMAIL_ERROR:
      return {
        ...state,
        change_email_error: action.error,
        change_email_pending: false,
      };
  }

  if (!localStorage.getItem("token") && state.token) {
    localStorage.setItem("token", state.token);
  }

  return state;
}
