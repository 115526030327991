import React from 'react';
import classNames from 'classnames'
import { Translate } from 'react-localize-redux';
import { childrenType } from '../../constants/types';

const BackBtn = ({ children, className, ...otherProps }) => {
  return (
    <button className={classNames("btn-secondary w-md btn", className)} {...otherProps} >
      {children}
    </button>
  )
};

BackBtn.propTypes = {
  children: childrenType,
};

BackBtn.defaultProps = {
  children: <Translate id="common.back" />,
};

export default BackBtn;
