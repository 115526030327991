import {ofType} from 'redux-observable';
import {flatMap, mergeMap, map, takeUntil, filter} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {API_ENDPOINT, NODE_ENDPOINT_HOT} from "../settings";
import {timer} from 'rxjs'
import {newRateUpdateSuccess, START_POLLING_NEW_RATE, STOP_POLLING_NEW_RATE} from "../actions/newRate";

// epic
// const fetchIndicatorsEpic = (action$, state$) => action$.pipe(
//     ofType(START_POLLING_INDICATORS),

//     map(action => action.payload),

//     flatMap(address =>
//         timer(0, 20000).pipe(
//             takeUntil(action$.pipe(ofType(STOP_POLLING_INDICATORS))),
//             flatMap(() =>
//                 ajax.getJSON(`${API_ENDPOINT}/indicators`).pipe(
//                     filter((response) => response.data.rate !== state$.value.Indicators.rate || response.data.maintenance !== state$.value.Indicators.maintenance),
//                     mergeMap((response) => [rateUpdateSuccess(response.data)]),
//                 ),
//             )
//         ),
//     )
// );

const fetchNewRatesEpicNew = (action$, state$) => action$.pipe(
    ofType(START_POLLING_NEW_RATE),

    map(action => action.payload),

    flatMap(address =>
        timer(0, 20000).pipe(
            takeUntil(action$.pipe(ofType(STOP_POLLING_NEW_RATE))),
            flatMap(() =>
                ajax.getJSON(`${NODE_ENDPOINT_HOT}/system`).pipe(
                    filter((response) => {
                     return response.rates.ouro !== state$.value.NewRate.rates.ouro || response.maintenance !== state$.value.NewRate.maintenance ||response.rates.sput !== state$.value.NewRate.rates.sput
                    }),
                    mergeMap((response) => [newRateUpdateSuccess(response)]),
                ),
            )
        ),
    )
);

// export default fetchIndicatorsEpic;
 export default fetchNewRatesEpicNew;