import { API_ENDPOINT } from "../settings";
import {
  authError,
  authPending,
  authSuccess,
  faRequired,
} from "../actions/user";

export default function loginAction(email, password, fa, recaptcha) {
  return (dispatch) => {
    // Мы в процессе загрузки данных
    dispatch(authPending());

    fetch(API_ENDPOINT + "/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        fa: fa,
        recaptcha: recaptcha,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.ok === false) {
          return dispatch(authError(res.message));
        }

        // Включена двухфакторка
        if (res.data.ask_for_fa === true) {
          return dispatch(faRequired());
        }

        return dispatch(authSuccess(res.data));
      })
      .catch((error) => {
        if (error.status >= 500 && !error.message) {
          error.message = "error.server_wrong";
        }
        dispatch(authError(error.message));
      });
  };
}
