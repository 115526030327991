import React from 'react'
import {connect} from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import { COLD_WALLET_ACCOUNT_TYPE } from '../../constants/common';

class PrivateRoute extends React.Component {
    render() {
        const {
            component: Component,
            user: { email, account_type },
            rest,
        } = this.props;

        const isLoggedIn = email !== "" || account_type === COLD_WALLET_ACCOUNT_TYPE;

        return (
            <Route
                {...rest}
                render={props =>
                    isLoggedIn ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
                }
            />
        )
    }
}
const mapStatetoProps = state => {
    return {
        user: state.User,
    };
};

export default connect(mapStatetoProps)(PrivateRoute);
