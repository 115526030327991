import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { ButtonGroup } from "reactstrap";
import { Translate, withLocalize } from "react-localize-redux";
import * as actionTypes from "../store/action";
import { Link } from "react-router-dom";
import { OpenWallet, CreateWallet, AcceptMnemo } from "../components/Mnemo";
import { ActionButton } from "../components/Buttons";
import AuthLayout from "../components/Layout/AuthLayout";
import BackBtn from "../components/Buttons/BackBtn";
import { generateMnemonic } from "../utils/crypto";
import { authMnemonicSuccess, setAccountType } from "../actions/user";
import {
  COLD_WALLET_ACCOUNT_TYPE,
  DEFAULT_ACCOUNT_TYPE,
} from "../constants/common";
import "./styles/Login.scss";
import ReactCountryFlag from "react-country-flag";
import ChooseLang from "../components/ChooseLang/ChooseLang";

const CREATE_ACCOUNT_MODE = "create";
const OPEN_ACCOUNT_MODE = "open";
const CREATE_MNEMO_MODE = "create_mnemo";
const ACCEPT_MNEMO_MODE = "accept_mnemo";

class MnemoPage extends PureComponent {
  state = {
    mode: null,
    stackMode: [],
    phrase: "",
    show: false,
    search: "",
  };

  componentDidMount() {
    this.onChange = this.onChange.bind(this);

    const { loginpage, dispatch } = this.props;

    if (!loginpage) {
      dispatch({ type: actionTypes.LOGINPAGE, value: true });
    }

    this.setState({ phrase: generateMnemonic() });

    dispatch(setAccountType(DEFAULT_ACCOUNT_TYPE));
  }

  setMode = (mode) => () =>
    this.setState((prevState) => ({
      mode,
      stackMode: [...prevState.stackMode, prevState.mode],
      phrase:
        mode === CREATE_MNEMO_MODE ? generateMnemonic() : prevState.phrase,
    }));

  onBackClick = () => {
    const { stackMode } = this.state;

    const mode = stackMode.pop();

    this.setState({ mode, stackMode });
  };

  onChange = (phrase) => {
    this.setState({ phrase: phrase });
  };

  onSubmit = () => {
    const { dispatch } = this.props;

    dispatch(authMnemonicSuccess(this.state.phrase));
    dispatch(setAccountType(COLD_WALLET_ACCOUNT_TYPE));
  };

  getContent = () => {
    const { mode, phrase } = this.state;

    switch (mode) {
      case CREATE_ACCOUNT_MODE:
        return (
          <div className="mnemo-btns">
            <h5 className="font-18 m-t-30">
              <Translate id="mnemo.create_wallet_title" />
            </h5>
            <p>
              <Translate id="mnemo.create_wallet_description" />
            </p>

            <div className="w-100 mt-4">
              <ActionButton
                type="button"
                className="rounded mnemo-btn-lg"
                onClick={this.setMode(CREATE_MNEMO_MODE)}
              >
                <Translate id="mnemo.create_mnemo" />
              </ActionButton>

              <BackBtn
                className="rounded mt-2 mnemo-btn-lg"
                type="button"
                onClick={this.onBackClick}
              />
            </div>
          </div>
        );

      case CREATE_MNEMO_MODE:
        return (
          <CreateWallet
            phrase={phrase}
            onAcceptClick={this.setMode(ACCEPT_MNEMO_MODE)}
            onBackClick={this.onBackClick}
          />
        );

      case ACCEPT_MNEMO_MODE:
        return (
          <AcceptMnemo
            phrase={phrase}
            onBackClick={this.onBackClick}
            onSubmit={this.onSubmit}
          />
        );

      case OPEN_ACCOUNT_MODE:
        return (
          <OpenWallet
            onBackClick={this.onBackClick}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
        );

      default:
        return (
          <div className="btn-group-vertical w-100 m-t-30 mnemo-btns">
            <ActionButton
              type="button"
              onClick={this.setMode(CREATE_ACCOUNT_MODE)}
              className="mnemo-btn"
            >
              <Translate id="mnemo.create_wallet" />
            </ActionButton>

            <ActionButton
              type="button"
              onClick={this.setMode(OPEN_ACCOUNT_MODE)}
              className="mnemo-btn"
            >
              <Translate id="mnemo.open_wallet" />
            </ActionButton>
          </div>
        );
    }
  };

  render() {
   
    return (
      <>
        <div id="page">
          <div id="box-4" style={{ zIndex: 7 }}>
            <div id="box-4-w">
              <ChooseLang
                languages={this.props.languages}
                setActiveLanguage={this.props.setActiveLanguage}
                activeLanguage={
                  this.props.activeLanguage && this.props.activeLanguage.code
                }
              />

              <Link to="/" className="logo">
                Ouroboros
              </Link>
            </div>
          </div>
          <div id="box-4">
            <div className="box-4-l">
              <h2>
                <Translate id="auth.welcome" />
              </h2>
              <p>
                <Translate id="auth.get_in" />
              </p>

              <div id="box-4-1-1">
                <Link to="/login">
                  <Translate id="auth.email_login" />
                </Link>
                <Link to="/mnemonic">
                  <Translate id="auth.memo_login" />
                </Link>
              </div>

              {this.getContent()}

              <div className="mt-4">
                <Link
                  data-cy="reset-password-link"
                  className="text-primary"
                  to="/reset-password"
                >
                  <i className="mdi mdi-lock" />{" "}
                  <Translate id="auth.forget_password" />
                </Link>
              </div>

              <div className="mt-4 text-center">
                <p>
                  <Translate id="auth.doesnt_have" /> &nbsp;
                  <Link
                    data-cy="register-link"
                    to="register"
                    className="font-500 text-primary"
                  >
                    <Translate id="auth.register" />
                  </Link>{" "}
                  &nbsp;
                </p>
              </div>
            </div>

            <div className="box-4-r">
              <img src={require("../assets/img/14-white.png")} alt="" />

              <p>Получите доступ к личному кабинету после регистрации</p>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "126px" }}
          className="lang"
          onClick={() => {
            this.setState({ ...this.state, show: !this.state.show });

            document.getElementsByClassName("rodal")[0].style.display = "";
          }}
        >
          {this.props.languages.map((l) => {
            if (l.active) {
              return (
                <div className="lang__block">
                  <ReactCountryFlag
                    countryCode={l.code === "en" ? "gb" : l.code}
                    svg
                  />
                  <div>{l.code.toUpperCase()}</div>
                  {!this.state.show ? (
                    <i class="fas fa-caret-down" />
                  ) : (
                    <i class="fas fa-caret-up" />
                  )}
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }
}

MnemoPage.propTypes = {
  loginpage: PropTypes.bool,
};

MnemoPage.defaultProps = {
  loginpage: false,
};

const mapStateToProps = (state) => ({
  loginpage: state.ui_red.loginpage,
  lang: state.Local.lang,
});

const enhance = compose(connect(mapStateToProps), withLocalize);

export default enhance(MnemoPage);
