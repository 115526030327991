import React, {Component} from 'react';
import {connect} from 'react-redux';
import loadProfile from "../api/fetchProfile";
import updateAccountAction from "../api/updateAccount";
import sendReinvest from "../api/sendReinvest";
import {resetReinvest} from "../actions/reinvest";
import {Translate, withLocalize} from "react-localize-redux";
import {SAVINGS} from "../settings";
import {COLD_WALLET_ACCOUNT_TYPE} from '../constants/common';
import Header from "../components/Header/Header";
import './styles/comptetition.css';
import participateAction from "../api/participate";
import convertCoins, {convertCoinsCeil} from "../utils/coins";
import StructureGraph from "../components/Graph/StructureGraph";


class Competition extends Component {
    state = {loading: false};

    constructor(props) {
        super(props);

        this.participate = this.participate.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(loadProfile(this.props.user.address));
    }

    participate() {
        this.props.dispatch(participateAction());

        this.setState({loading: true});
    }

    render() {
        const {user, account} = this.props;

        let prize = "~";

        if (user.competition_place === 1) {
            prize = "TOYOTA CAMRY";
        } else if (user.competition_place === 2 || user.competition_place === 3) {
            prize = "MacBook Pro 2019";
        } else if (user.competition_place === 4 || user.competition_place === 5) {
            prize = "IPHONE 11 PRO";
        }


        if (user.in_competition) {
            return (
                <div id="box-7-w">
                    <div id="box-7">
                        <div id="top-w">
                            <Header address={user.address}/>
                        </div>
                        <div className="clear"></div>
                        <div className="box-7-container">
                            <h2><Translate id={"competition.current_results"}/>:</h2>
                            <div className="box-7-w">

                                <div className="box-7 box-7-4">
                                    <p className="p7-1"><span>{user.competition_place}</span></p>
                                    <p className="p7-2"><Translate id={"competition.your_place"}/><br/><br/></p>
                                    <div className="clear"></div>
                                </div>
                                <div className="box-7 box-7-1">
                                    <p className="p7-1"><span>{convertCoinsCeil(account.structure.balance)}</span> OURO
                                    </p>
                                    <p className="p7-2"><Translate id={"competition.volume"}/><br/><br/></p>
                                    <div className="clear"></div>
                                </div>
                                <div className="box-7 box-7-2">
                                    <p className="p7-1"><span>{user.competition_next}</span> OURO</p>
                                    <p className="p7-2"><Translate id={"competition.next_place"}/></p>
                                    <div className="clear"></div>
                                </div>
                                <div className="box-7 box-7-3">
                                    <p className="p7-1"><span>{account.structure.followers}</span></p>
                                    <p className="p7-2"><Translate id={"competition.followers"}/><br/><br/></p>
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>
                                <div className="box-7-5">
                                    <p className="p7-4"><Translate id={"competition.structure_growing"}/></p>
                                    <div className="graph">
                                        <StructureGraph history={user.history}/>
                                    </div>
                                </div>
                                <div className="box-7-6">
                                    <p className="p7-5"><Translate id={"competition.prize"}/></p>
                                    <img src="/images/47.png" alt=""/>
                                    <p className="p7-6">{prize}</p>
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            );
        }

        const deactivated = false;


        return (
            <div id="box-6-w">
                <div id="box-6">
                    <div id="top-w">
                        <Header address={user.address}/>
                    </div>

                    <div className="box-6-w">
                        <h2><Translate id={"competition.need_to"}/>:</h2>
                        <a href="https://ouroboros-2020.com" target={"_blank"} className="button-6-1"><Translate
                            id={"competition.details"}/></a>
                        <div className="clear"></div>
                        <div className="box-6">
                            <p className="p6-1"><span><Translate id={"competition.step"}/> 01</span><br/><div><Translate
                                id={"competition.step1"}/></div></p>
                            <p className="p6-2"><span><Translate id={"competition.step"}/> 02</span><br/><div><Translate
                                id={"competition.step2"}/></div></p>
                            <p className="p6-3"><span><Translate id={"competition.step"}/> 03</span><br/><div><Translate
                                id={"competition.step3"}/></div></p>
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>

                        {!user.can_participate ?
                            <a href="" className="button-6-2 deactive"><Translate id={"competition.im_in"}/></a> :
                            <a onClick={this.participate} className="button-6-2"><Translate
                                id={"competition.im_in"}/></a>}
                        {!user.can_participate ?
                            <p className="p6-4"><Translate id={"competition.need_to_create"}/></p> : null}
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    account: state.Profile,
    user: state.User,
    reinvest: state.Reinvest
});


export default connect(mapStateToProps)(withLocalize(Competition));
