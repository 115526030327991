
import { NEW_RATE_UPDATE_SUCCESS } from "../actions/newRate";

// export const INITIAL_STATE = {
//     rate: 0.35,
//     maintenance: false,
// };

// export default function (state = INITIAL_STATE, action) {
//     switch (action.type) {
//         case RATE_UPDATE_SUCCESS:
//             return {
//                 ...state,
//                 rate: action.data.rate,
//                 maintenance: action.data.maintenance,
//             };
//     }

//     return state;
// }

export const INITIAL_STATE = {
    rates: {
      ouro: 0.000004,
      sput: 1
    },
    maintenance: false
};

export default function (state = INITIAL_STATE, action) {
  
    switch (action.type) {
        case NEW_RATE_UPDATE_SUCCESS:
            return {
                ...state,
                maintenance:action.data.maintenance,
                rates:{...state.rates,ouro:action.data.rates.ouro,sput:action.data.rates.sput}
            
            };
            
            default: return state
        
    }
}